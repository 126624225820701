import $ from 'jquery'

export function init () {
  if (process.client) {
    $('body').on('widget:playerVideo', (event) => {
      $('.video-wrapper').on('click', function () {
        console.log('global--->', Object.keys(global))
        const store = global.$store
        let videoUrl = $(this).data('video-url')
        store.commit('cms/setUrlVideoModal', videoUrl)
      })
    })
  }
}
