import $ from 'jquery'

let currentPage = 1
let options = {}

let selectors = {
  'button': '.aminst-loader-wrapper',
  'post_item': '.aminst-feed-post',
  'post_items': '.aminst-feed-posts',
  'container': '.aminst-feed-grid-container'
}

export function init () {
  if (process.client) {
    $('body').on('instagram:init', (event, options) => {
      window.amastyOptions = options
    })
    $('body').on('click', '.aminst-loader-wrapper', loadMore.bind(this))
    // listen for popup
    $('body').on('click', '.aminst-feed-link', openPopup)
  }
}
function initialize () {
  // object key where to find settings
  options = window.amastyOptions
}

function loadMore (event) {
  initialize()
  let $element = $(selectors.container)
  let button = $(event.target)
  let data = getNextPageData()
  let parent = $element.find(selectors.post_items)
  let blockData = options.block_data

  if (!button.is('button')) {
    button = button.parent()
  }

  if (data.length) {
    blockData['load_page'] = currentPage + 1
    let template = getPostTemplate()
    let tpl
    data.forEach(figure => {
      tpl = template
      if (figure.product_id && figure.product_entity) {
        tpl = tpl.replace('{{product_name}}', figure.product_entity.name)
          .replace('{{product_price}}', figure.product_entity.price)
          .replace('{{product_url}}', figure.product_entity.url)
      } else {
        tpl = tpl
          .replace('{{product_name}}', '')
          .replace('{{product_price}}', '')
          .replace('{{product_url}}', '')
      }

      parent.append(
        tpl.replace('{{permalink}}', figure.permalink)
          .replace('{{media_url}}', figure.media_url)
          .replace('{{like_count}}', figure.like_count)
          .replace('{{comments_count}}', figure.comments_count)
          .replace('{{caption}}', figure.caption)
      )
    })
    currentPage++
    if (!getNextPageData().length) {
      button.hide()
    }
  }
}

function getPostTemplate () {
  let finalTemplate = ''
  let htmlTemplate = ''
  let $list = $('.aminst-feed-posts .aminst-feed-post').length
  if ($list) {
    htmlTemplate = $('.aminst-feed-posts .aminst-feed-post').first().clone()
  }
  if (htmlTemplate) {
    htmlTemplate.find('.aminst-feed-img').attr('src', '{{media_url}}')
    htmlTemplate.find('.aminst-feed-link').attr('title', '')
    htmlTemplate.find('.aminst-feed-link').attr('href', '{{permalink}}')
    htmlTemplate.find('.aminst-feed-caption').text('{{caption}}')
    htmlTemplate.find('.aminst-feed-likes').text('{{like_count}}')
    htmlTemplate.find('.aminst-feed-comments').text('{{comments_count}}')
    htmlTemplate.find('.post-relation .post-relation__name').text('{{product_name}}')
    htmlTemplate.find('.post-relation .post-relation__price').text('{{product_price}}')
    htmlTemplate.find('.post-relation .post-relation__url').text('{{product_url}}')
    finalTemplate = htmlTemplate[0].outerHTML
  } else {
    finalTemplate = `
      <figure class="aminst-feed-post -thumbnail" data-aminst-js="post-item">
        <a class="aminst-feed-link -dis-desc" href="{{permalink}}" target="_blank" data-aminst-js="feed-link">
          <img class="aminst-feed-img" src="{{media_url}}" alt="Instagram Image">
          <figurecaption class="aminst-feed-comment-cont">
            <p class="aminst-feed-review">
              <span class="aminst-feed-likes">{{like_count}}</span>
              <span class="aminst-feed-comments">{{comments_count}}</span>
            </p>
            <p class="aminst-feed-caption">{{caption}}</p>
          </figurecaption>
        </a>
      </figure>`
  }
  return finalTemplate
}

function getNextPageData () {
  let data = options.all_posts
  let perPage = options.per_page
  return data.slice(perPage * currentPage, perPage * (currentPage + 1))
}

function openPopup (event) {
  initialize()
  event.preventDefault()
  let $currentPost = $(event.target).parents('.aminst-feed-post')
  let modalObject = {
    imagePlaceholder: options.imagePlaceholder,
    image: $currentPost.find('.aminst-feed-img').attr('src'),
    permalink: $currentPost.find('.aminst-feed-link').attr('href'),
    description: replaceDescription($currentPost.find('.aminst-feed-caption').text()),
    likeCount: $currentPost.find('.aminst-feed-likes').text(),
    commentCount: $currentPost.find('.aminst-feed-comments').text(),
    product_entity: {
      name: $currentPost.find('.post-relation .post-relation__name').text(),
      price: $currentPost.find('.post-relation .post-relation__price').text(),
      url: $currentPost.find('.post-relation .post-relation__url').text()
    }
  }
  $('body').trigger('modal:instagram', modalObject)
}

function replaceDescription (text) {
  text = replaceHashTag(text)
  text = replaceTaggedAccount(text)
  return text
}

function replaceHashTag (text) {
  // replace hashtag and tagged account
  const instagramHashTagLink = 'https://www.instagram.com/explore/tags'
  text = text.replace(/#(\w+)/g, `<a href="${instagramHashTagLink}/$1" class="hashtag-link" target="_blank">#$1</a>`)
  return text
}

function replaceTaggedAccount (text) {
  const instagramAccountLink = 'https://www.instagram.com'
  text = text.replace(/@(\w+)/g, `<a href="${instagramAccountLink}/$1" class="hashtag-link" target="_blank">@$1</a>`)
  return text
}
