export default {
  messages: {
    required: '必須フィールド',
    requireTrue: '必須フィールド',
    phoneNumber: '無効な電話番号',
    alpha: 'アルファベットのみを入力してください',
    email: '無効な電子メール',
    min: (fieldName, params) => `${fieldName} には少なくとも ${params[0]} 文字が必要です`,
    confirmed: (fieldName) => `${fieldName} は入力されたものと一致しません`,
    numeric: '数値のみ',
    length: '長さが正しくありません'
  }
}
