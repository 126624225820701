
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

function getState (storeListing) {
  storeListing.state.filters['temporary'] = {}
  storeListing.state.productVisible = 0
  return storeListing
}

export default function extendListing (storeListing) {
  storeListing.state = { ...storeListing.state, ...getState(storeListing) }
  storeListing.getters = { ...storeListing.getters, ...getters }
  storeListing.mutations = { ...storeListing.mutations, ...mutations }
  storeListing.actions = { ...storeListing.actions, ...actions }
  return storeListing
}
