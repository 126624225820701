<template>
  <div class="row">
    <generic-filter
      class="col-md-3"
      v-for="(filter, attrCode) in availableFilters"
      :key="attrCode"
      :attrCode="attrCode"
      :label="filter.label"
      :options="filter.options"
      @changeFilter="changeFilter"
    />
  </div>
</template>

<script>
import GenericFilter from '@/theme/components/Listing/GenericFilter'
export default {
  props: {
    availableFilters: {
      type: Object,
      required: true
    },
    filtersActive: {
      type: Object,
      default: null
    }
  },
  methods: {
    changeFilter ($event) {
      return this.$emit('changeFilter', $event)
    }
  },
  components: {
    GenericFilter
  }
}
</script>
