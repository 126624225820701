import { EventBus } from '@/helpers'
import { mobileDetector, getCurrentPrice } from '@/helpers/utils'
import { getProductUrl } from '@/helpers/routeHelpers'
import Logger from '@/services/Logger'
import $ from 'jquery'
import Config from '@/config'

const trackListener = [
  'track:init',
  'track:addToCart',
  'track:clickProduct',
  'track:deleteProduct',
  'track:newsletter'
]
class Tracker {
  constructor ({ store, wrapper, app, router }) {
    this.store = store
    this.wrapper = wrapper
    this.app = app
    this.router = router
    this.firstCall = true
    this.loadContainer()
    this.activeListener()
  }
  evtTofx (evtName) {
    return evtName.replace('track:', '')
  }
  getImageFromProduct (product) {
    if (product) {
      if (product.hasOwnProperty('image')) {
        return product.image.url
      } else if (product.media_gallery.length) {
        return product.media_gallery[0].url
      } else if (product.hasOwnProperty('small_image')) {
        return product.small_image.url
      } else {
        return ''
      }
    }
  }
  activeListener () {
    const _this = this
    trackListener.forEach(track => {
      EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this))
    })
    // listener route change
    this.router.afterEach((to, from) => {
      // first time wait user load
      if (!_this.firstCall) {
        Logger.debug('trackEvent:afterEach')
        _this.init()
        _this.reloadAllContainer()
      }
    })
    // this.store.subscribe(subscriber)
    Logger.debug('trackEvent:activeListener')
  }

  reloadAllContainer (options) {
    this.wrapper.reloadAllContainers(options)
  }

  removeListener () {
    trackListener.forEach(track => {
      EventBus.$off(track)
    })
  }

  iCanTrack () {
    Logger.debug('check track', global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : true)
    return global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : true
  }

  /**
   * check if is Mobile, tablet or Desktop
   */
  getChannel () {
    // m, t, d
    const _mobileDetector = mobileDetector()
    if (_mobileDetector) {
      if (_mobileDetector.mobile()) {
        return 'm'
      }
      if (_mobileDetector.tablet()) {
        return 't'
      }
      return 'd'
    }
    return 'not found'
  }

  loadContainer () {
    // Creation of TC Containers
    const dd = Date.now()
    this.wrapper.addContainer('container_head', `${Config.Theme.tagCommander.tc_head}?time=${dd}`, 'head')
    this.wrapper.addContainer('container_body', `${Config.Theme.tagCommander.tc_main}?time=${dd}`, 'body')
    // this.wrapper.addContainer('container_body', '/tagCommander/tc_main.js', 'body')
  }

  getTemplateName () {
    let metaTpl = this.app.$route.meta.tplName
    if (metaTpl === 'factoryPage') {
      const currentComponent = this.store.state.cms.currentComponent
      if (currentComponent === 'ProductPage') {
        metaTpl = 'product'
      } else if (currentComponent === 'CmsPage') {
        metaTpl = 'newsletter_form'
      } else if (currentComponent === 'CategoryPage') {
        if (this.store.state.ui.breadcrumb.length > 2) {
          metaTpl = 'category'
        } else {
          metaTpl = 'category_main'
        }
      }
    }
    metaTpl = metaTpl || 'other_generic'
    return metaTpl
  }

  getCurrency () {
    return this.store.state.storeConfig.config.base_currency_code
  }

  /**
   * ------TRACK INIT
   */
  init () {
    if (this.iCanTrack()) {
      Logger.debug('trackEvent:init')
      window.trackEnable = true
      const tplName = this.getTemplateName()
      this.wrapper.setTcVars({
        env_template: tplName,
        env_work: process.env.VUE_APP_PRODUCTION === 'true' ? 'prod' : 'test',
        env_channel: this.getChannel(),
        env_language: this.store.getters['storeConfig/language'],
        env_country: this.store.state.ui.currentCountry ? this.store.state.ui.currentCountry.country : '',
        env_currency: this.getCurrency()
      })
      this.setUserVar()
      this.setCategoryVal()
      this.setBasketVal()
      this.setProductVal()
      if (tplName === 'funnel_confirmation') {
        this.setOrderVal()
      }
      // this.loadContainer()
      this.triggerTpl()
    }
    this.firstCall = false
  }
  triggerTpl () {
    // lunch trigger update tpl
    if (global.tC && global.tC.event && global.tC.event.container_reload) {
      this.wrapper.captureEvent('container_reload', $(document)[0], {
        'id': 'container_reload'
      })
    } else {
      setTimeout(this.triggerTpl.bind(this), 1000)
    }
  }
  setUserVar () {
    const userInfo = this.store.state.user.current
    this.wrapper.setTcVars({
      'user_id': userInfo ? userInfo.id : '', // to do -- call return null
      'user_gender': '',
      'user_age': '',
      'user_recency': '', // last order date
      'user_frequency': 0, // last order number
      'user_amount': 0, // total orders
      'user_email': userInfo ? userInfo.email : '',
      'user_email_optin': userInfo ? (userInfo.is_subscribed ? 1 : 0) : 0,
      'user_newcustomer': userInfo ? 0 : 1
    })
  }

  setCategoryVal () {
    const currentCat = this.store.state.cms.currentComponent === 'CategoryPage' ? this.store.state.category.current : null
    const breadcrumb = currentCat ? this.store.state.ui.breadcrumb : null
    this.wrapper.setTcVars({
      'page_cat1': breadcrumb && breadcrumb.length > 1 ? breadcrumb[1].text : '', // main Category
      'page_cat2': breadcrumb && breadcrumb.length > 2 ? breadcrumb[2].text : '', // Category
      'page_cat3': breadcrumb && breadcrumb.length > 3 ? breadcrumb[3].text : '', // sub Category
      'page_cat4': breadcrumb && breadcrumb.length > 4 ? breadcrumb[4].text : '',
      'page_error': this.app.$route.meta.tplName && this.app.$route.meta.tplName === 'other_errors' ? '404' : '',
      'category_id': currentCat ? currentCat.id : '',
      'category_name': currentCat ? currentCat.name : '',
      'list_products': this.getListProducts(currentCat)
    })
  }

  setBasketVal () {
    const currentCart = this.store.state.cart.cartId
    const totals = this.store.state.cart.total
    if (currentCart && Object.keys(totals).length) {
      this.wrapper.setTcVars({
        'basket_id': currentCart,
        // Order amount ATI (all tax included) without shipping fee
        'order_amount_ati_without_sf': '',
        // Order amount ATI (all tax included) with shipping fee
        'order_amount_ati_with_sf': this.formatPrice(totals.subtotal_incl_tax),
        // Order discount ATI (all tax included) - let empty if no discount
        'order_discount_ati': this.formatPrice(totals.base_discount_amount) * -1,
        // Order amount TF (tax free) without shipping fee
        'order_amount_tf_without_sf': '',
        // Order amount TF (tax free) with shipping fee
        'order_amount_tf_with_sf': this.formatPrice(totals.base_subtotal),
        // Order discount TF (tax free) - let empty if no discount
        'order_discount_tf': this.formatPrice(this.getDiscountNoTax(totals)),
        // Payment methods (VISA, CB,MASTERCARD,AMEX,…) - JavaScript array if several payment methods for the same order
        'order_promo_code': totals.coupon_code,
        // Number of different products into the order
        'order_products_number': totals.items_qty,
        'order_products': this.getOrderProducts(totals.items),
        'list_products': []
      })
    }
  }

  getListProducts (currentCat) {
    let products = []
    if (currentCat) {
      let productsState = this.store.state.listing.list
      for (let i = 0; i < 3 && i < productsState.length; i++) {
        products.push(this.getInfoListProduct(productsState[i]))
      }
    }
    return products
  }

  getInfoListProduct (product) {
    return {
      'list_product_id': product.id,
      'list_product_name': product.name,
      'list_product_currentprice_ati': getCurrentPrice(product).current, // product all tax included
      'list_product_url_page': getProductUrl({ store: this.store, product }),
      'list_product_url_picture': this.getImageFromProduct(product)
    }
  }

  setProductVal () {
    const currentProduct = this.store.state.cms.currentComponent === 'ProductPage' ? this.store.state.product.current : null
    const priceInfo = currentProduct ? getCurrentPrice(currentProduct) : null
    this.wrapper.setTcVars({
      'product_id': currentProduct ? currentProduct.id : '',
      'product_name': currentProduct ? currentProduct.name : '',
      'product_url_page': currentProduct ? getProductUrl({ store: this.store, product: currentProduct }) : '',
      'product_url_picture': this.getImageFromProduct(currentProduct),
      'product_originalprice_ati': this.formatPrice(priceInfo ? (priceInfo.regularPrice ? priceInfo.regularPrice : priceInfo.current) : 0),
      'product_discount_ati': this.formatPrice(priceInfo ? priceInfo.discount : 0),
      'product_currentprice_ati': this.formatPrice(priceInfo ? priceInfo.current : 0)
      /* 'product_originalprice_tf': currentProduct ? currentProduct.id : '',
      'product_discount_tf': currentProduct ? currentProduct.id : '',
      'product_currentprice_tf': currentProduct ? currentProduct.id : '' */
    })
  }

  /**
   * ---- TRACK setOrderVal
   */
  setOrderVal () {
    const order = this.store.state.orders.currentOrder
    if (order && order.items) {
      this.wrapper.setTcVars({
        'env_currency': order.base_currency_code,
        'order_id': order.increment_id,
        'basket_id': order.quote_id,
        // Order amount ATI (all tax included) without shipping fee
        'order_amount_ati_without_sf': this.formatPrice(order.base_grand_total - order.base_shipping_amount),
        // Order amount ATI (all tax included) with shipping fee
        'order_amount_ati_with_sf': this.formatPrice(order.base_grand_total),
        // Order discount ATI (all tax included) - let empty if no discount
        'order_discount_ati': this.formatPrice(order.base_discount_amount) * -1 || '',
        // Order amount TF (tax free) without shipping fee
        'order_amount_tf_without_sf': this.formatPrice(this.getOrderAmountNoTax(order, (order.base_grand_total - order.base_shipping_amount))),
        // Order amount TF (tax free) with shipping fee
        'order_amount_tf_with_sf': this.formatPrice(this.getOrderAmountNoTax(order)),
        // Order discount TF (tax free) - let empty if no discount
        'order_discount_tf': this.formatPrice(this.getDiscountNoTax(order)),
        // Payment methods (VISA, CB,MASTERCARD,AMEX,…) - JavaScript array if several payment methods for the same order
        'order_payment_methods': order.payment.method,
        'order_promo_code': order.coupon_code,
        // Number of different products into the order
        'order_products_number': order.total_item_count,
        'order_products': this.getOrderProducts(this.store.getters['orders/getProduct']),
        'user_email': order.customer_email
      })
      /* this.reloadAllContainer()
      this.triggerTpl() */
    }
  }
  getOrderProducts (products) {
    let productId
    return products.map(product => {
      productId = product.product_id ? product.product_id : parseInt(product.extension_attributes.product_id)
      return {
        'order_product_id': productId,
        'order_product_sku': product.sku || product.extension_attributes.sku,
        'order_product_name': product.name,
        'order_product_quantity': product.qty_ordered || product.qty,

        // all tax incluede
        'order_product_originalprice_ati': this.formatPrice(product.base_price_incl_tax),
        'order_product_discount_ati': this.formatPrice(this.getItemDiscountAmount(product)),
        'order_product_currentprice_ati': this.formatPrice(product.base_price_incl_tax),

        // tax free
        'order_product_originalprice_tf': this.formatPrice(product.base_price),
        'order_product_discount_tf': this.formatPrice(this.getItemDiscountAmount(product, false)),
        'order_product_currentprice_tf': this.formatPrice(product.base_price),

        'order_product_url_page': '',
        'order_product_url_picture': product.extension_attributes.image_url
      }
    })
  }

  formatPrice (price) {
    return parseFloat(price).toFixed(2)
  }

  // copied from mod_tbuy_tagcommander
  getItemDiscountAmount (product, tax = true) {
    let discountAmount = product.discount_amount
    let taxPercent = 1 + product.tax_percent / 100
    if (tax) {
      return discountAmount
    }
    return discountAmount / taxPercent
  }

  /**
   *
   * copied from mod_tbuy_tagcommander
   */
  getDiscountNoTax (order) {
    let discountNoTax = 0
    if (order && order.items && order.items.length >= 1) {
      let taxPercent = order.items[0].tax_percent
      discountNoTax = (order.discount_amount * -1) / (1 + taxPercent / 100)
    }
    return discountNoTax
  }

  getOrderAmountNoTax (order, totalAmount = null) {
    let totalNoTax = 0
    if (order && order.items && order.items.length >= 1) {
      let taxPercent = order.items[0].tax_percent || order.items[1].tax_percent
      totalNoTax = totalAmount || order.base_grand_total
      totalNoTax = totalNoTax / (1 + taxPercent / 100)
    }
    return totalNoTax
  }

  /* getAmount(order, tax = true, shippingFee = true) {
    let total = 0

    if (tax) {
    total = order.base_subtotal_incl_tax
    } else {
      $total = order.base_subtotal
    }

    if (shippingFee) {
      $cod = $sale->getCodFee();
      $codInclTax = $sale->getCodFee() + $sale->getCodTaxAmount();
      if ($tax) {
        $total += $sale->getShippingInclTax() - $sale->getShippingDiscountAmount() + $codInclTax;
      } else {
        $shippingTaxPercent = 1 + $sale->getShippingTaxPercent() / 100;
        $shippingDiscountNoTax = $sale->getShippingDiscountAmount() / $shippingTaxPercent;
        $total += $sale->getShippingAmount() - $shippingDiscountNoTax + $cod;
      }
    }

    return number_format($total, 2, ".", "");
  } */

  /**
   * --------TRACK adToCart
   */
  addToCart ({ $event, product, prodComplete }) {
    if (!this.iCanTrack()) {
      return false
    }
    let params = {
      'id': 'add_to_cart',
      'product_id': product ? product.item_id : prodComplete.item_id,
      'product_sku': product ? product.sku : '',
      'product_name': product ? product.name : prodComplete.name
    }
    if (prodComplete) {
      params['product_sku'] = prodComplete.extension_attributes ? prodComplete.extension_attributes.sku : ''
      params['product_originalprice_ati'] = this.formatPrice(prodComplete.price_incl_tax)
      params['product_discount_ati'] = this.formatPrice(prodComplete.discount_amount)
      params['product_currentprice_ati'] = this.formatPrice(prodComplete.price_incl_tax)
      // params['product_url_page'] = getProductUrl({ store: this.store, prodComplete })
      params['product_url_picture'] = prodComplete.extension_attributes ? prodComplete.extension_attributes.image_url : ''
    } else {
      params['product_originalprice_ati'] = this.formatPrice(product.price)
      params['product_currentprice_ati'] = this.formatPrice(product.price)
    }
    this.wrapper.captureEvent('add_to_cart', product ? $event.target : $event, params)
  }
  /**
   * ----------Track click product from category page
   */
  clickProduct ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    const priceInfo = getCurrentPrice(product)
    this.wrapper.captureEvent('click_prod', $event.target, {
      'id': 'click_prod',
      'product_id': product.id,
      'product_sku': product.sku,
      'product_name': product.name,
      'product_originalprice_ati': this.formatPrice(priceInfo ? (priceInfo.regularPrice ? priceInfo.regularPrice : priceInfo.current) : 0),
      'product_discount_ati': this.formatPrice(priceInfo ? priceInfo.discount : 0),
      'product_currentprice_ati': this.formatPrice(priceInfo ? priceInfo.current : 0),
      'product_url_page': getProductUrl({ store: this.store, product }),
      'product_url_picture': product.image ? product.image.url : undefined
    })
  }
  /**
   * delete product
   */
  deleteProduct ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('delete_product', $event.target, {
      'id': 'delete_product',
      'product_id': product.item_id,
      'product_sku': product.extension_attributes ? product.extension_attributes.sku : undefined,
      'product_url_picture': product.extension_attributes ? product.extension_attributes.image_url : undefined,
      'product_name': product.name,
      'product_originalprice_ati': this.formatPrice(product.price),
      'product_currentprice_ati': this.formatPrice(product.price)
    })
  }
  /**
   *  track newsletter
   */
  newsletter ({ $event, email }) {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('nl_form', $event.target, {
      'id': 'nl_form',
      'user_email': email
    })
  }
}

export default Tracker
