import Vuex from 'vuex'
import { extendStore } from '@/helpers/extendCore'
import Logger from '@/services/Logger'
// import Config from '@/config'

export function initStore (toRoute, store) {
  Logger.debug('init store')
  let token = null
  let cartId = null
  let syncCart = null

  if (process.client) {
    if (global.$cookies) {
      cartId = global.$cookies.get('cartId') || null
      token = global.$cookies.get('token') || null
      syncCart = global.$cookies.get('sync-cartId') || null
      store.commit('ui/setHideTopBannerBadge', global.$cookies.get('hide-top-banner-badge') === 'true')
    }
  } else {
    // server side
    if (global.ctx && global.ctx.cookie) {
      cartId = global.ctx.cookie['cartId'] || null
      token = global.ctx.cookie['token'] || null
      syncCart = global.ctx.cookie['sync-cartId'] || null
      store.commit('ui/setHideTopBannerBadge', global.ctx.cookie['hide-top-banner-badge'] === 'true')
    }
  }
  // check if clean token cart
  if (syncCart !== store.state.ui.storeViewCode + '-' + cartId) {
    cartId = null
  }

  if (toRoute && toRoute.name === 'thankyou') {
    if (toRoute.query && toRoute.query.token) {
      if (!token) {
        // user regitered
        store.commit('user/newUser', true)
      }
      token = toRoute.query.token
      if (process.client) {
        global.$cookies && global.$cookies.set('token', token, { httponly: true })
      } else {
        global.ctx.cookie['token'] = token
      }
    }
  }

  store.commit('cart/updCartId', cartId)
  store.commit('user/setToken', token)
  return store
}

export default () => {
  let storeObj = extendStore()
  /* const isPresentErrorHandler = !!store.state.errorHandler
  if (isPresentErrorHandler) {
    store.unregisterModule('errorHandler')
  } */

  let newStore = new Vuex.Store(storeObj)
  Logger.debug('**************** NEW STORE ************')
  global.$store = newStore
  return newStore
}
