export default `query GetPosts($currentPage: Int, $pageSize: Int, $filters: BlogPostsFilterInput) {
  blogPosts(currentPage: $currentPage, pageSize: $pageSize, sort: ["DESC"], sortFiled: "publish_time", filter: $filters ) {
    items {
      identifier
      post_id
      title
      content_heading
      short_content
      first_image
      is_active
      publish_time
      categories{
        title
        identifier
      }
    }
    total_pages
    total_count
  }
}`
