// symbol: '$'
// precision: 2, .00
// pattern: '! #', =====>  ! -> symbol | # -> number
// negativePattern: '! -#', ====>  ! -> symbol | -# -> number
// decimal: ',', =====>  ,00  | .00
// separator: '.' ====> 10.000
export default {
  'EUR': {
    symbol: '€', precision: 2, pattern: '# !', negativePattern: '-# !', decimal: ',', separator: '.'
  },
  'USD': {
    symbol: '$', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'JPY': {
    symbol: '¥', precision: 0, pattern: '# !', negativePattern: '-# !', decimal: '.', separator: ','
  },
  'CAD': {
    symbol: 'CA$', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'GIP': {
    symbol: '£', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'GBP': {
    symbol: '£', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'AUD': {
    symbol: 'A$', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'CHF': {
    symbol: 'CHF', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'DKK': {
    symbol: 'kr', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'KRW': {
    symbol: '₩', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'NOK': {
    symbol: 'kr', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'PLN': {
    symbol: 'zł', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'SEK': {
    symbol: 'Kr', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'BYN': {
    symbol: 'Br', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: '.', separator: ','
  },
  'BYR': {
    symbol: 'p.', precision: 2, pattern: '!#', negativePattern: '!-#', decimal: '.', separator: ','
  },
  'RUB': {
    symbol: 'RUB', precision: 2, pattern: '! #', negativePattern: '! -#', decimal: ',', separator: '.'
  }
}
