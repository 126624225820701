<template>
    <b-modal :hide-header="true" :hide-footer="true" v-model="show">
      <div class="container-body text-right">
        <button type="button" class="btn btn-icon close-modal" @click="show=false">
          <i class="aqz-icon aqz-icon-04-close"></i>
        </button>
      </div>
      <div class="container-body text-center">
        <span class="container-body text-center">{{ $t('qrCodeModal.title') }}</span>
      </div>
      <div class="container-body text-center">
        <img id="qr-code" :src="data"/>
      </div>
    </b-modal>
</template>
<script>

import QRCode from 'qrcode'

export default {
  props: {
    urlQrCode: {
      type: String,
      require: true
    },
    ModalShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      data: undefined
    }
  },
  computed: {
    show: {
      get () {
        return this.$store.state.ui.modalQrCode
      },
      set (newValue) {
        this.$store.commit('ui/showModalQrCode', newValue)
      }
    }
  },
  mounted () {
    QRCode.toDataURL(this.urlQrCode)
      .then(url => {
        this.data = url
      })
      .catch(err => {
        console.error(err)
      })
  }
}
</script>
