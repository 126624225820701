import { processInclude } from '@/helpers/utils'
import widgets from './widgets'
import windowGuard from './windowGuard'
import $ from 'jquery'

if (process.client) {
  global.$ = $
  processInclude(widgets)
  processInclude(windowGuard)
}
