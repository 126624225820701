export default `query urlResolver ($url: String!) {
  urlResolver (url: $url) {
    id
    status
    redirectCode
    seo_redirect_custom {
      redirect_code
      redirect_url
      redirect_type_resource
    }
    type
  }
}`
