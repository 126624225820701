import { init as swiperInit } from './swiper'
import { init as shareBtnInit } from './shareBtn'
import { init as videoPLayerInit } from './videoPlayer'
import { init as imagehandlerInit } from './imagehandler'
import { init as instagramFeedInit } from './instagramFeed'
import { init as jquerySmoothScroll } from './jQuerySmoothScroll'
import { init as swiperItemVideo } from './swiperItemVideo'
import { init as halfImageWithText } from './halfImageWithText'
import { init as dobleImage } from './dobleImage'
import { init as initQuickBuyFromMagento } from './initQuickBuyFromMagento'

export default {
  swiperInit,
  shareBtnInit,
  imagehandlerInit,
  instagramFeedInit,
  jquerySmoothScroll,
  videoPLayerInit,
  swiperItemVideo,
  halfImageWithText,
  dobleImage,
  initQuickBuyFromMagento
}
