import { user } from '@/store/modules/user'
import { ui } from '@/store/modules/ui'
import { checkout } from '@/store/modules/checkout'
import { attribute } from '@/store/modules/attribute'
import { listing } from '@/store/modules/listing'
import { product } from '@/store/modules/product'
import { category } from '@/store/modules/category'
import { cart } from '@/store/modules/cart'
import { cms } from '@/store/modules/cms'
import { orders } from '@/store/modules/orders'
import { giftCard } from '@/store/modules/giftCard'
import { storeConfig } from '@/store/modules/storeConfig'
import { looksBook } from '@/store/modules/looksBook'
import { feelbuy } from './modules/feelbuy'
import { storeLocator } from './modules/storeLocator'

import userStore from '@/store/modules/user/store'
import cartStore from '@/store/modules/cart/store'
import uiStore from '@/store/modules/ui/store'
import checkoutStore from '@/store/modules/checkout/store'

import extendListing from './modules/listing'
import extendUi from './modules/ui'
// import rightnav from './modules/rightnav'

export default () => {
  return {
    modules: {
      user: user(),
      ui: extendUi(ui()),
      checkout: checkout(),
      attribute: attribute(),
      listing: extendListing(listing()),
      product: product(),
      category: category(),
      cart: cart(),
      cms: cms(),
      orders: orders(),
      giftCard: giftCard(),
      feelbuy: feelbuy(),
      looksBook: looksBook(),
      storeLocator: storeLocator(),
      storeConfig: storeConfig()
    },
    plugins: [
      userStore,
      cartStore,
      uiStore,
      checkoutStore
    ]
  }
}
