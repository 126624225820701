<template>
  <div class="search-teaser" @click.prevent="$emit('search-clicked')">
    <div class="input-simulate form-control">
      <span class="placeholder">{{ $t('Search') + '...' }}</span>
      <i class="aqz-icon aqz-icon-11-search"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'searchTeaser'
}
</script>
