import $ from 'jquery'
import _ from 'lodash'
import { isMobile } from '@/helpers/utils'

export function initDomClasses (className = 'page-slider-hero') {
  $('html').attr('data-class', className)
  $('body').trigger('windowGuard:resize')
  calcAppDistance({})
  return true
}

function calcAppDistance ({ initStatic }) {
  if (initStatic) {
    $('#app main').css('margin-top', 0)
  } else if (!isMobile()) {
    let $hheight = $('.whole-navigation').height()
    $('#app main').css('margin-top', '-' + $hheight + 'px')
  }
  return true
}

export function init () {
  if (process.client) {
    $('body').on('windowGuard:resize', (event) => {
      $(window).off('resize.headerCalc')
      $(window).on('resize.headerCalc', _.debounce(function () {
        calcAppDistance({ initStatic: false })
      }, 400))
      $(window).trigger('resize:headerCalc')
    })
  }

  $('body').on('windowGuard:resize:off', () => {
    calcAppDistance({ initStatic: true })
    $(window).off('resize.headerCalc')
  })
}
