export default {
  messages: {
    required: 'champ requis',
    requireTrue: 'champ requis',
    phoneNumber: 'Numéro de téléphone invalide',
    alpha: 'entrer uniquement des caractères alphabétiques',
    email: 'email invalide',
    min: (fieldName, params) => `le ${fieldName} doit avoir au moins ${params[0]} caractères`,
    confirmed: (fieldName) => `Le ${fieldName} ne correspond pas à celui saisi`,
    numeric: 'valeurs numériques uniquement',
    length: 'la longueur est incorrecte'
  }
}
