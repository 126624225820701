<template>
  <li @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')">
    <div :class="[category.id === 44 ? 'nav-link sales' : 'nav-link']">
      <router-link
        class="text-decoration-none"
        v-if="category.childrenData.length === 0"
        :to="'listing' | localizedByNameCategories(category.url_path, null, $store, $router)"
        @click.native="linkClicked"
      >{{ category.name }}</router-link>
      <span class="link-label" v-else>{{ category.name }}</span>
    </div>
    <div class="block-subcat-nav" v-if="category.childrenData.length">
      <div class="subcat-navigation">
        <div class="aqz-container">
          <b-row>
            <component :is="loadSubmenuByLayout" :category="category"></component>
          </b-row>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import find from 'lodash/find'
import WithTitle from './Layout/WithTitle' // default
import WithoutTitle from './Layout/WithoutTitle'
import SingleColumn from './Layout/SingleColumn'
import { EventBus } from '@/helpers'

const layout = {
  'with-title': 'WithTitle',
  'without-title': 'WithoutTitle',
  'single-column': 'SingleColumn'
}

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    loadSubmenuByLayout () {
      let componentToLoad = WithTitle
      let componentMatch = find(this.category.custom_attributes, { attribute_code: 'custom_frontend_layout' })
      if (componentMatch && componentMatch.value && layout[componentMatch.value]) {
        componentToLoad = layout[componentMatch.value]
      }
      return componentToLoad
    }
  },
  methods: {
    linkClicked () {
      EventBus.$emit('category:change', true)
      window.pagePosition = null
    }
  },
  components: {
    WithTitle,
    WithoutTitle,
    SingleColumn
  }
}
</script>
