import Config from '@/config'

export default `query suggestionsProduct (
  $search: String!
  $filter: ProductAttributeFilterInput
  ) {
  products (
    search: $search,
    filter: $filter,
    currentPage: 1,
    pageSize: ${Config.Theme.searchSuggestions.productLimit}) {
    aggregations {
      label
      count
      attribute_code
      options {
        label
        value
      }
    }
    aggregationsStore {
      label
      count
      attribute_code
      options {
        label
        value
      }
    }
    items {
      id
      name
      special_price
      special_from_date
      special_to_date
      aqa_sottotitolo
      small_image {
        url
        label
      }
      image{
        label
        url
      }
      url_key
      price_range {
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          discount {
            amount_off
            percent_off
          }
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
      }
      price_tiers {
        discount {
          amount_off
          percent_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
    }
    page_info {
      total_pages
    }
    total_count
  }
}`
