import Vue from 'vue'
import $ from 'jquery'

export default Vue.directive('append-html', {
  inserted: (el, binding) => {
    if ($(el).text().trim().length === 0) {
      updateContainer(el, binding)
    }
  },
  update: (el, binding) => {
    updateContainer(el, binding)
  }
})

function updateContainer (el, binding) {
  $(el).html(binding.value || '')
}
