import { toast } from '@/modules/notifications'
export function init () {
  if (process.client) {
    global.$(document).on('click', '.products-grid .cta-quick-buy', (event) => {
      const Store = global.$store
      event.preventDefault()
      let href = global.$(event.currentTarget).attr('data-url-key')
      Store.commit('ui/showLoader')
      Store.dispatch('product/loadByUrlKey', href)
        .then((resp) => {
          Store.dispatch('listing/manageQuickView', resp)
            .catch((error) => {
              throw error
            })
        })
        .catch((error) => {
          toast.error(error)
        })
        .finally(() => {
          Store.commit('ui/hideLoader')
        })
    })
  }
}
