<template>
  <a :href="categoryLinkGenerator" @click.prevent="followLink($event, categoryLinkGenerator)">
    <slot name="content">
      <span class="link-label">{{ showLabel }}</span>
    </slot>
  </a>
</template>

<script>
import Vue from 'vue'
import find from 'lodash/find'

export default {
  name: 'LinkManager',
  props: {
    category: {
      type: Object,
      required: true
    },
    forceClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    }
  },
  computed: {
    showLabel () {
      if (this.label) {
        return this.label
      } else {
        return this.category.name
      }
    },
    categoryIsClickable () {
      if (this.forceClickable) {
        return true
      } else {
        let attr = find(this.category.custom_attributes, { attribute_code: 'is_clickable' })
        if (attr && Boolean(parseInt(attr.value))) return true
        return false
      }
    },
    categoryLinkGenerator () {
      if (this.categoryIsClickable) {
        return Vue.filter('localizedByNameCategories')('listing', this.category.url_path, null, this.$store, this.$router)
      } else {
        return 'javascript:void(0)'
      }
    }
  },
  methods: {
    followLink (event, url) {
      if (url === 'javascript:void(0)') {
        event.preventDefault()
        return true
      } else {
        if (typeof url === 'string' && url.startsWith('http')) {
          // is external
          location.href = url
        } else {
          // somethimes if route is loaded
          // the nav remain open
          // using this method to add a loader for a while
          // in this way a layer override over status
          this.$router.push({ path: url })
        }
      }
    }
  }
}
</script>
