import Config from '@/config'
import Magento from '@/services/Magento'
import { restWithCache } from '@/store/lib/search'
import graphListing from './graphQlquery/listing'
import graphProduct from './graphQlquery/product'
import productConfigurable from './graphQlquery/productConfigurable'
import { ordersList, getOrderById } from './graphQlquery/orders'
import { saveWishlistItem, removeWishlistItem, moveAllToCart, addGifCardToWishlist } from './graphQlquery/wishlist'
import { addGiftCardOnCart } from './graphQlquery/giftCardCart'
import { getFeelBuyAttributes } from './graphQlquery/feelbuy'
import graphCart from './graphQlquery/cart'
import blogCategories from './graphQlquery/blogCategories'
import blogCategory from './graphQlquery/blogCategory'
import blogPosts from './graphQlquery/blogPosts'
import blogPost from './graphQlquery/blogPost'
import { setPickupIdOnCart, getPickupLocationIdFromCode, getStorePickupByCountry, getStoreLocatorConfig } from './graphQlquery/storePickup'
import { graphSocialLogin } from './graphQlquery/socialLogin'
import graphCustomer from './graphQlquery/customer'
import updateCustomer from './graphQlquery/updateCustomer'
import updateAddress from './graphQlquery/updateAddress'
import createAddress from './graphQlquery/createAddress'
import deleteAddress from './graphQlquery/deleteAddress'
import rmaList from './graphQlquery/rmaList'
import rmaDetail from './graphQlquery/rmaDetail'
import rmaUserRecap from './graphQlquery/rmaUserRecap'
import rmaOrdersAvailable from './graphQlquery/rmaOrdersAvailable'
import checkGuestOrder from './graphQlquery/checkGuestOrder'
import rmaNewPrepare from './graphQlquery/rmaNewPrepare'
import rmaCreate from './graphQlquery/rmaCreate'
import cardList from './graphQlquery/cardList'
import cardDisable from './graphQlquery/cardDisable'
import urlResolve from './graphQlquery/urlResolve'
import storeConfig from './graphQlquery/storeConfig'
import suggestionsProduct from './graphQlquery/suggestionsProduct'
import singleLookbook from './graphQlquery/singleLookBook'
import mergeCarts from './graphQlquery/mergeCarts'
import customerCart from './graphQlquery/customerCart'
import customerChangePassword from './graphQlquery/customerChangePassword'
import unsubscribeEmail from './graphQlquery/unsubscribeEmail'
import categoryById from './graphQlquery/categoryById'
import variantExt from './graphQlquery/variantExt'

const graphSchema = {
  graphListing,
  graphProduct,
  productConfigurable,
  blogCategories,
  blogCategory,
  blogPosts,
  blogPost,
  ordersList,
  getOrderById,
  graphCart,
  graphCustomer,
  graphSocialLogin,
  getFeelBuyAttributes,
  saveWishlistItem,
  removeWishlistItem,
  moveAllToCart,
  addGifCardToWishlist,
  updateCustomer,
  updateAddress,
  createAddress,
  deleteAddress,
  rmaList,
  rmaDetail,
  rmaOrdersAvailable,
  checkGuestOrder,
  rmaNewPrepare,
  rmaUserRecap,
  rmaCreate,
  cardList,
  cardDisable,
  urlResolve,
  storeConfig,
  suggestionsProduct,
  singleLookbook,
  mergeCarts,
  customerCart,
  addGiftCardOnCart,
  customerChangePassword,
  unsubscribeEmail,
  categoryById,
  variantExt,
  setPickupIdOnCart,
  getPickupLocationIdFromCode,
  getStorePickupByCountry,
  getStoreLocatorConfig
}

class Graphql {
  // constructor () { }
  toGraphQlFilter (jsonObj) {
    let convertedStr = ''
    let value
    for (var key in jsonObj) {
      value = typeof jsonObj[key] === 'object' && !Array.isArray(jsonObj[key]) ? `{${this.toGraphQlFilter(jsonObj[key])}}` : JSON.stringify(jsonObj[key])
      convertedStr += `${key}:${value},`
    }
    return convertedStr.slice(0, -1)
  }

  parserGraphQl (filter) {
    return this.toGraphQlFilter(filter)
      .replace(':"DESC"', ':DESC')
      .replace(':"ASC"', ':ASC')
  }
  doListing (config, token, storeViewCode) {
    let query = graphListing
    return this.doCall(query, config.searchGraph, token, storeViewCode)
  }
  doProduct (filter, token, storeViewCode) {
    let query = graphProduct
    return this.doCall(query, { filter }, token, storeViewCode)
  }

  genericCall (config, token, storeViewCode, graphSchemaType, variables = null, useCash = true, option = null) {
    let query = option && option.query ? option.query : graphSchema[graphSchemaType]
    for (var key in config) {
      query = query.replace(`{{${key}}}`, this.parserGraphQl(config[key]))
    }
    return this.doCall(query, variables, token, storeViewCode, useCash)
  }

  // // CART QUERY
  // getCartItems (cartID, token, storeViewCode) {
  //   let query = graphCart.getItems()
  //   query = query.replace('{{cartId}}', cartID)
  //   return this.doCall(query, token, storeViewCode)
  // }

  // CART MUTATION
  addCouponOnCart (cartID, couponCode, token, storeViewCode) {
    let query = graphCart.addCoupon()
    let variables = {
      input: {
        cart_id: cartID,
        coupon_code: couponCode
      }
    }
    return { query, variables, token, storeViewCode }
    // doCall on page src\theme\components\Checkout\Cart\Coupon.vue
  }
  removeCouponFromCart (cartID, token, storeViewCode) {
    let query = graphCart.removeCoupon()
    let variables = {
      input: {
        cart_id: cartID
      }
    }
    return { query, variables, token, storeViewCode }
    // doCall on page src\theme\components\Checkout\Cart\Coupon.vue
  }

  doCall (query, variables = null, token, storeViewCode, useCash = true) {
    let config = {
      headers: {
        'Authorization': '',
        'Store': storeViewCode
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    // useCash on call for GET request
    // do not use cache when request need a data update
    if (useCash) {
      return restWithCache({
        query: { header: config.headers },
        body: { query, variables, storeViewCode },
        restPromise: Magento.post(Config.API.graphql, { query, variables }, config)
      })
    } else {
      return Magento.post(Config.API.graphql, { query, variables }, config).then(res => res.data)
    }
  }

  doMutation (query, variables = null, token, storeViewCode) {
    let config = {
      headers: {
        'Authorization': '',
        'Store': storeViewCode
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return Magento.post(Config.API.graphql, { query, variables }, config)
  }

  genericCallCash ({ token, storeViewCode, graphSchemaType, variables, useCash = true }) {
    let cleanQuery = graphSchema[graphSchemaType].replace(/(\s){2,}/g, ' ')
    let query = cleanQuery
    return this.graphCash({ query, variables, token, storeViewCode, useCash, id: graphSchemaType })
  }
  graphCash ({ query, variables, token, storeViewCode, useCash, id }) {
    let data = {
      params: { query, variables, store: storeViewCode },
      headers: {
        'Authorization': '',
        'Store': storeViewCode
      }
    }
    if (token) {
      data.headers.Authorization = `Bearer ${token}`
    }
    // useCash on call for GET request
    // do not use cache when request need a data update
    if (useCash) {
      return restWithCache({
        query: id,
        body: data,
        restPromise: Magento.get(Config.API.graphql, data)
      })
    } else {
      return Magento.get(Config.API.graphql, data).then(res => res.data)
    }
  }
}

const instanceGraph = new Graphql()

export default instanceGraph
