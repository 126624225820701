<template>
  <div class="product-info__color">
    <div class="color-title" v-show="showTitle">
      <strong><span class="text-uppercase">{{$t('color')}}</span> &nbsp;</strong><span class="text-uppercase d-inline-block">{{colorSelectedLabel}}</span>
    </div>
    <div class="wrapp-color-list" :class="{ 'has-overflow': hasOverflow }">
      <no-ssr>
        <overlay-scrollbars class="color-list" :options="{ scrollbars: { visibility: 'h' }, overflowBehavior: { y: 'hidden', x: 'scroll'} }" ref="siblingScrollbar">
          <div class="swatch-option" v-for="sibling in siblingProducts" :key="sibling.color" :class="{'active' : sibling.color === colorSelected }">
            <div class="swatch-option__radio color-radio" :style="{ 'background-image': `url(${sibling.color_image || sibling.image_url})` }" :aria-label="sibling.color_label" @click.prevent="change(sibling)"></div>
          </div>
        </overlay-scrollbars>
      </no-ssr>
      <div class="move-between_left" v-show="hasOverflow">
        <i @click.prevent="scrollColorSelectorToLeft()" class="aqz-icon aqz-icon-33-slider-arrow-left"></i>
      </div>
      <div class="move-between" v-show="hasOverflow">
        <i @click.prevent="scrollColorSelectorToRight()" class="aqz-icon aqz-icon-33-slider-arrow-left"></i>
      </div>
    </div>
  </div>
</template>

<script>

import sortBy from 'lodash/sortBy'

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hasOverflow: false
    }
  },
  computed: {
    siblingProducts () {
      // create array
      let siblings = []
      if (this.product.sibling_products) {
        siblings = this.product.sibling_products.map((el) => {
          return {
            ...el,
            image_url: el.image.url ? `${el.image.url}?w=200` : ''
          }
        })
      }
      siblings.push({
        color: this.colorSelected,
        color_image: this.product.color_image,
        color_label: this.colorSelectedLabel,
        image_url: this.product.image.url ? `${this.product.image.url}?w=200` : '',
        url_key: this.product.url_key,
        url_suffix: this.product.url_suffix
      })
      return sortBy(siblings, ['color'])
    },
    colorSelected () {
      return this.product.color
    },
    colorSelectedLabel () {
      return this.product.color_label
    }
  },
  methods: {
    scrollColorSelectorToRight () { this.$refs['siblingScrollbar'].osInstance().scroll({ x: '+=80px' }, 600) },
    scrollColorSelectorToLeft () { this.$refs['siblingScrollbar'].osInstance().scroll({ x: '-=80px' }, 600) },
    scrollBarHasOverflow () {
      // check if scrollbar has overflow
      // if yes show arrow
      if (this.$refs['siblingScrollbar'] && this.$refs['siblingScrollbar'].osInstance()) {
        let scrollbar = this.$refs['siblingScrollbar'].osInstance().getState().hasOverflow
        this.hasOverflow = Object.values(scrollbar).some(value => value)
        return this.hasOverflow
      } else {
        this.hasOverflow = false
        return false
      }
    },
    change (sibiling) {
      // const _this = this
      const finishPath = sibiling.url_key + sibiling.url_suffix
      let beforePath = ''
      if (this.$store.state.cms.currentComponent === 'ProductPage') {
        beforePath = this.$route.fullPath
        beforePath = beforePath.substr(0, beforePath.lastIndexOf('/') + 1)
        this.$router.push(beforePath + finishPath)
      }
      // else {
      //   // category path
      //   this.$store.commit('ui/showLoader')
      //   this.$store.dispatch('product/loadByUrlKey', sibiling.url_key + sibiling.url_suffix)
      //     .then(product => {
      //       _this.$store.dispatch('listing/manageQuickView', product)
      //     })
      //     .finally(() => {
      //       _this.$store.commit('ui/hideLoader')
      //     })
      // }
      this.$emit('siblingChange', sibiling)
    }
  },
  mounted () {
    if (process.client) {
      this.$nextTick(() => {
        this.scrollBarHasOverflow()
      })
    }
  }
}
</script>
