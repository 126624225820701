<template>
  <div class="checkbox-filter">
    <no-ssr>
      <b-form-group>
        <b-form-checkbox-group
          :name="`checkbox_${attrCode}`"
          v-model="selected"
          :options="optionToUse"
          class="single-item"
          @change="change"
        />
      </b-form-group>
    </no-ssr>
  </div>
</template>

<script>
export default {
  props: {
    attrCode: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    optionChosen: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    optionToUse () {
      const _this = this
      return this.options.map(opt => ({
        value: opt.value,
        html: (['0', '1'].includes(opt.label) ? opt.label === opt.value ? _this.$t('label_' + opt.label) : opt.label : opt.label).toLowerCase()
      }))
    },
    selected: {
      get () {
        return this.optionChosen
      },
      set (newValues) {
        return newValues
      }
    }
  },
  methods: {
    change (newValues) {
      this.$emit('changeFilter', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { attrCode: this.attrCode, valueSelected: newValues }
    }
  }
}
</script>
