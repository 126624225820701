import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Config from '@/config'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = Config.Theme.enablei18n ? require.context('./theme/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i) : require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default (store) => {
  const localeComplete = store.state.ui.locale ? store.state.ui.locale : process.env.VUE_APP_I18N_LOCAL
  const language = localeComplete.substr(0, 2)
  global.$i18n = new VueI18n({
    locale: language || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
  })
  return global.$i18n
}
