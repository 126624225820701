/**
 * $filter = ex: { sku: { eq: "SKU"}}
 * $search = "SKU"
 */
import Config from '@/config'

function getProduct () {
  const attrArr = JSON.stringify(Config.Theme.attributesToload)
  return `query Products($filter: ProductAttributeFilterInput) {
      products(filter: $filter) {
      total_count
      items {
        id
        one_size
        home_product
        name
        sku
        url_key
        url_suffix
        meta_title
        meta_description
        meta_keyword
        meta_robots
        head_content_page
        seo_markup_product
        tax_rate
        new_from_date
        new_to_date
        is_exclusive_online
        out_of_stock_threshold
        attributesLabel (attributes_identifier: ${attrArr}) {
          label
          value
          attribute_id
          attribute_code
        }
        description { html }
        short_description { html }
        table_ideas
        set
        aqa_designer_tips
        aqa_craftmanship
        aqa_3dcode
        aqa_instruction
        canonical_url
        custom_canonical_link
        href_lang_links{href, hreflang}
        color
        color_label
        color_image
        master_sku
        sibling_products {
          url_key
          url_suffix
          color
          color_label
          color_image
          image {
            url
          }
        }
        categories{
          breadcrumbs{
            category_level
            category_name
            category_url_path
          }          
        }
        aqa_fit_suggestion
        aqa_video_care
        aqa_video_designer
        description {
          html
        }
        image {
          label
          url
        }
        small_image{
          url
          label
        }
        media_gallery {
          label
          url
          position
          disabled
          ... on ProductVideo {
            position
            video_content {
              media_type
              video_provider
              video_url
            }
          }
        }
        media_gallery_entries {
          types
          file
        }
        special_price
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value,
              currency
          }
          quantity
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
            fixed_product_taxes {
              amount {
                value
              }
              label
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
        categories {
          id
        }
        related_products {
          attribute_set_id
          url_key
          url_suffix
          sku
          name
          image {
            label
            url
          }
          media_gallery{
            url
            position
          }
          media_gallery_entries {
            types
            file
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
              fixed_product_taxes {
                amount {
                  value
                  currency
                }
                label
              }
            }
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
              fixed_product_taxes {
                amount {
                  value
                  currency
                }
                label
              }
            }
          }
        }
        upsell_products {
          attribute_set_id
          url_key
          url_suffix
          sku,
          name,
          image {
            label
            url
          }
          media_gallery{
            url
            position
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              },
              fixed_product_taxes {
                amount {
                  value
                  currency
                },
                label
              }
            },
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
              fixed_product_taxes {
                amount {
                  value
                  currency
                },
                label
              }
            },
          },
        }
        ... on AwGiftCardProduct {
          aw_gc_allow_delivery_date
          aw_gc_allow_open_amount
          aw_gc_amounts
          aw_gc_custom_message_fields
          aw_gc_days_order_delivery
          aw_gc_description
          aw_gc_email_templates {
            image_url
            name
            value
          }
          aw_gc_expire
          aw_gc_open_amount_max
          aw_gc_open_amount_min
          aw_gc_pool
          aw_gc_type
        }
        ... on ConfigurableProduct {
          color
          options {
            title
            required
            sort_order
          }
          configurable_options {
            id,
            label
            attribute_code
            attribute_id
            position
            product_id
            values {
              value_index
              label
              default_label
              store_label
              use_default_value
              translations(attribute_code: "size"){
                label
                zone
              }
            }
          }
          variants {
            attributes {
              label
              code
              value_index
            }
            product {
              id
              name
              sku
              out_of_stock_threshold
              stock_status
              attribute_set_id
              quantity
              special_from_date
              special_price
              special_to_date
              price_tiers {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value,
                    currency
                }
                quantity
              }
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                },
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                }
              }
            }
          }
        }
      }
    }
  }`
}

const product = getProduct()
export default product
