import Router from 'vue-router'
import Config from '@/config'
import { pathTranslate, externalCheckoutUrl } from '@/helpers/routeHelpers'
import { setToken as MagentoSetToken } from '@/services/Magento'
import { EventBus } from '@/helpers'

export default () => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      let position = savedPosition || { x: 0, y: 0 }
      setTimeout(() => {
        EventBus.$emit('listing:scrollBehavior', position)
      }, 100)
      return position
    },
    routes: [
      {
        path: '/',
        redirect: { name: 'home', params: { lang: 'defa_lt' } }
      },
      {
        path: `/:lang(${Config.Theme.storeviewRegex})`,
        component: () => import('../components/Lang.vue'),
        children: [
          {
            path: '',
            name: 'home',
            component: () => import('../pages/Home.vue'),
            meta: {
              tplName: 'homepage'
            }
          },
          {
            path: pathTranslate({ path: 'reset-password' }), // /?token=GbLLDEuDsXNAG5BLNXhaKCFtIPsZ4dEa&email=...'
            name: 'resetPassword',
            component: () => import('./pages/ResetPassword.vue'),
            meta: {
              tplName: 'myaccount_other'
            }
          },
          {
            path: 'register',
            name: 'register',
            component: () => import('./pages/Register.vue'),
            meta: {
              tplName: 'myaccount_other'
            }
          },
          {
            path: 'newsletter',
            name: 'Newsletter',
            component: () => import('./pages/Newsletter.vue'),
            meta: {
              tplName: 'newsletter'
            }
          },
          {
            path: 'my-account',
            meta: {
              tplName: 'myaccount_other',
              auth: { name: 'home' }
            },
            component: () => import('./pages/MyAccount/MyAccount.vue'),
            children: [
              {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('./pages/MyAccount/Dashboard.vue'),
                meta: {
                  accountPage: true,
                  tplName: 'myaccount_other',
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-profile',
                name: 'myProfile',
                component: () => import('./pages/MyAccount/MyProfile.vue'),
                meta: {
                  accountPage: true,
                  tplName: 'myaccount_other',
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-addresses',
                name: 'myAddresses',
                component: () => import('./pages/MyAccount/MyAddresses.vue'),
                meta: {
                  accountPage: true,
                  tplName: 'myaccount_other',
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-addresses/:id',
                name: 'editAddress',
                component: () => import('./pages/MyAccount/MyAddressesEdit.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  auth: { name: 'home' },
                  title: 'addEditAddress'
                }
              },
              {
                path: 'my-orders',
                name: 'myOrders',
                component: () => import('./pages/MyAccount/MyOrders.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  accountPage: true,
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-orders/:id',
                name: 'myOrder',
                component: () => import('./pages/MyAccount/MySingleOrder.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  auth: { name: 'home' },
                  title: 'my-order'
                }
              },
              {
                path: 'my-wishlist',
                name: 'myWishList',
                component: () => import('./pages/MyAccount/MyWishlist.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  accountPage: true,
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-returns',
                name: 'myReturns',
                component: () => import('./pages/MyAccount/MyReturns.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  accountPage: true,
                  auth: { name: 'home' }
                }
              },
              {
                path: 'my-returns/edit/:id',
                name: 'editReturns',
                component: () => import('./pages/MyAccount/MyReturnsEdit.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  auth: { name: 'home' },
                  title: 'detailRma'
                }
              },
              {
                path: 'my-returns/new',
                name: 'newReturns',
                component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  auth: { name: 'home' },
                  title: 'newRma'
                }
              },
              {
                path: 'my-cards',
                name: 'myCards',
                component: () => import('./pages/MyAccount/MyCards.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  accountPage: true,
                  auth: { name: 'home' }
                }
              }
            ]
          },
          {
            path: pathTranslate({ path: 'terms-and-conditions' }),
            component: () => import('./pages/TermsAndConditions/TermsAndConditions.vue'),
            children: [
              {
                path: '',
                redirect: pathTranslate({ path: 'conditions-of-sale' })
              },
              {
                path: pathTranslate({ path: 'return-refunds' }) + '/init',
                name: 'createGuestReturn',
                component: () => import('./pages/TermsAndConditions/CreateGuestReturn.vue'),
                meta: {
                  title: 'init',
                  tplName: 'myaccount_other'
                }
              },
              {
                path: pathTranslate({ path: 'return-refunds' }) + '/new',
                name: 'new',
                redirect: { name: 'createGuestReturn' },
                component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  title: 'newRma'
                }
              },
              {
                path: pathTranslate({ path: 'return-refunds' }) + '/new/:id',
                name: 'new',
                redirect: { name: 'createGuestReturn' },
                component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  title: 'newRma'
                }
              },
              {
                path: pathTranslate({ path: 'return-refunds' }) + '/new/:id/:email',
                name: 'newGuestReturn',
                component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  title: 'newRma'
                }
              },
              {
                path: pathTranslate({ path: 'return-refunds' }) + '/complete',
                name: 'returnComplete',
                component: () => import('./pages/MyAccount/MyReturnComplete.vue'),
                meta: {
                  tplName: 'myaccount_other',
                  title: 'rmaComplete'
                }
              },
              {
                path: ':slug',
                name: 'genericTerms',
                component: () => import('./pages/Factory.vue'),
                meta: {
                  tplName: 'other_generic',
                  basePath: pathTranslate({ path: 'terms-and-conditions' })
                }
              }
            ]
          },
          {
            path: pathTranslate({ path: 'store-locator' }),
            name: 'StoreLocator',
            component: () => import('./pages/StoreLocator.vue'),
            meta: {
              urlKey: pathTranslate({ path: 'aquazzura-world' }) + '/' + pathTranslate({ path: 'about-aquazzura' }) + '/' + pathTranslate({ path: 'boutiques' }),
              showMap: true,
              tplName: 'other_store',
              title: 'store-locator'
            }
          },
          {
            path: pathTranslate({ path: 'aquazzura-world' }) + '/' + pathTranslate({ path: 'about-aquazzura' }) + '/' + pathTranslate({ path: 'boutiques' }) + '.html',
            name: 'Boutiques',
            component: () => import('./pages/StoreLocator.vue'),
            meta: {
              urlKey: pathTranslate({ path: 'aquazzura-world' }) + '/' + pathTranslate({ path: 'about-aquazzura' }) + '/' + pathTranslate({ path: 'boutiques' }),
              tplName: 'other_store',
              title: 'boutiques'
            }
          },
          {
            path: pathTranslate({ path: 'boutique' }) + '/:store_code',
            redirect: { name: 'Boutique' }
          },
          {
            path: pathTranslate({ path: 'aquazzura-world' }) + '/' + pathTranslate({ path: 'boutiques' }) + '/:store_code',
            name: 'Boutique',
            component: () => import('./pages/StoreDetails.vue'),
            meta: {
              tplName: 'other_store'
            }
          },
          {
            path: pathTranslate({ path: 'corporate' }) + '/' + pathTranslate({ path: 'work-with-us' }),
            name: 'WorkWithUs',
            component: () => import('./pages/CustomerCare/WorkWithUs.vue'),
            meta: {
              tplName: 'other_generic'
            }
          },
          {
            path: pathTranslate({ path: 'customer-care' }),
            component: () => import('./pages/CustomerCare/CustomerCare.vue'),
            children: [
              {
                name: 'CustomerCare',
                path: '',
                redirect: { name: 'ContactUs' }
              },
              {
                path: pathTranslate({ path: 'contact-us' }),
                name: 'ContactUs',
                component: () => import('./pages/CustomerCare/ContactUsSaleForce.vue'),
                meta: {
                  tplName: 'other_generic'
                }
              },
              {
                path: pathTranslate({ path: 'after-care-sales' }),
                name: 'AfterCareSales',
                component: () => import('./pages/CustomerCare/AfterCareSales.vue'),
                meta: {
                  tplName: 'other_generic'
                }
              },
              {
                path: pathTranslate({ path: 'track-order' }),
                name: 'trackOrder',
                component: () => import('./pages/TrackOrder.vue'),
                meta: {
                  tplName: 'myaccount_other'
                }
              },
              {
                path: ':slug',
                name: 'genericCustomerCare',
                component: () => import('./pages/Factory.vue'),
                meta: {
                  tplName: 'other_generic',
                  basePath: pathTranslate({ path: 'customer-care' })
                }
              }
            ]
          },
          {
            path: 'product-care',
            component: () => import('./pages/ProductCare/ProductCare.vue'),
            name: 'ProductCare',
            children: [
              {
                path: '/',
                name: 'genericProductCare',
                component: () => import('./pages/Factory.vue'),
                meta: {
                  tplName: 'other_generic',
                  basePath: pathTranslate({ path: 'product-care' })
                }
              },
              {
                path: ':slug',
                name: 'genericProductCare',
                component: () => import('./pages/Factory.vue'),
                meta: {
                  tplName: 'other_generic',
                  basePath: pathTranslate({ path: 'product-care' })
                }
              }
            ]
          },
          {
            path: pathTranslate({ path: 'faq' }),
            redirect: to => ({
              name: 'genericCustomerCare',
              params: { slug: pathTranslate({ path: 'faq' }), lang: to.params.lang }
            })
          },
          {
            path: 'feel-shop.html',
            name: 'Feel&Shop',
            component: () => import('./pages/FeelBuy.vue'),
            meta: {
              tplName: 'other_generic'
            }
          },
          {
            path: 'feel-shop.html/results',
            name: 'FeelBuyListing',
            component: () => import('./pages/FeelBuyListing.vue'),
            meta: {
              tplName: 'other_generic'
            }
          },
          // {
          //   path: pathTranslate({ path: 'unsubscribe' }),
          //   name: 'unsubscribe',
          //   component: () => import('./pages/Unsubscribe.vue'),
          //   meta: {
          //     tplName: 'newsletter_form'
          //   }
          // },
          {
            path: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}.html/:cat?`,
            name: 'blog-landing',
            component: () => import('./pages/Blog/BlogLanding.vue'),
            meta: {
              parentBlog: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}`,
              tplName: 'other_generic'
            }
          },
          {
            path: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}/:cat?`,
            name: 'blog-landing',
            component: () => import('./pages/Blog/BlogLanding.vue'),
            meta: {
              parentBlog: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}`,
              tplName: 'other_generic'
            }
          },
          {
            path: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}/:cat/:post`,
            name: 'blog',
            component: () => import('./pages/Blog/Blog.vue'),
            meta: {
              parentBlog: `${pathTranslate({ path: 'aquazzura-world' })}/${pathTranslate({ path: 'blog' })}`,
              tplName: 'other_generic'
            }
          },
          /**
           * END BLOG
           */
          {
            path: 'search.html',
            name: 'search',
            component: () => import('./pages/Listing.vue'),
            meta: {
              search: true,
              tplName: 'internal_search'
            }
          },
          {
            path: 'checkout',
            component: () => import('./pages/CheckoutProcess.vue'),
            children: [
              {
                path: '',
                name: 'checkout',
                component: () => import('./pages/CheckoutOnePage.vue'),
                meta: {
                  tplName: 'funnel_step2'
                }
              },
              {
                path: 'cart',
                name: 'cart',
                component: () => import('./components/Checkout/Cart.vue'),
                meta: {
                  tplName: 'funnel_basket'
                }
              },
              {
                path: 'prePLaceOrder',
                name: 'prePLaceOrder',
                component: () => import('./pages/PrePlaceOrder.vue'),
                meta: {
                  tplName: 'funnel_step2'
                }
              },
              {
                path: 'shipping',
                name: 'shipping',
                meta: { auth: { name: 'cart' } },
                component: () => import('../components/Checkout/Shipping.vue')
              },
              {
                path: 'billing',
                name: 'billing',
                meta: { auth: { name: 'cart' } },
                component: () => import('../components/Checkout/Billing.vue')
              },
              {
                path: 'one',
                name: 'onepagecheckout',
                // meta: { auth: { name: 'cart' } },
                component: () => import('../components/Checkout/OnePage.vue')
              },
              {
                path: 'pre-summary',
                name: 'preSummary',
                component: () => import('../components/Checkout/PreSummary.vue')
              }
            ]
          },
          {
            path: 'thankyou',
            name: 'thankyou',
            component: () => import('./components/Checkout/Thankyou.vue'),
            meta: {
              tplName: 'funnel_confirmation'
            }
          },
          {
            path: 'adyenreturn',
            name: 'adyenreturn',
            // meta: { auth: { name: 'cart' } },
            component: () => import('./pages/AdyenReturn.vue'),
            meta: {
              tplName: 'funnel_step2'
            }
          },
          /* {
            path: 'pagina-non-trovata',
            name: 'it_it_page-not-found',
            component: () => import('../pages/PageNotFound.vue'),
            meta: {
              tplName: 'other_errors'
            }
          }, */
          /* {
            path: 'page-not-found',
            name: 'page-not-found',
            component: () => import('../pages/PageNotFound.vue'),
            meta: {
              tplName: 'other_errors'
            }
          }, */
          {
            path: 'page-not-found',
            name: 'page-not-found',
            meta: {
              tplName: 'other_errors'
            },
            redirect: { name: 'home' }
          },
          /* {
            path: 'static/:slug',
            name: 'staticPage',
            component: () => import('../pages/Static.vue')
          }, */
          {
            path: ':slug*',
            /* name: 'staticPage',
            component: () => import('../pages/Static.vue') */
            name: 'factoryPage',
            component: () => import('./pages/Factory.vue'),
            meta: {
              tplName: 'factoryPage'
            }
          }// ,
          /* {
            path: '*',
            redirect: { name: 'it-IT_page-not-found' }
          } */
        ]
      },
      /* {
        path: '*',
        redirect: { name: `${process.env.VUE_APP_STOREVIEWCODE}_page-not-found`, params: { lang: process.env.VUE_APP_STOREVIEWCODE } }
      } */
      {
        path: '*',
        redirect: { name: `home`, params: { lang: 'defa_lt' } }
      }
    ]
  })

  const whiteList = ['thankyou']
  router.beforeEach((to, from, next) => {
    if (to.name === 'thankyou' && 'rPage' in to.query) {
      next({ name: 'home', params: to.params })
      return false
    }
    if (process.client) {
      const store = global.$store
      const cartId = global.$cookies.get('cartId') || store.state.cart.cartId
      const token = global.$cookies.get('token') || store.state.user.token || ''

      // sync cart & user
      // sync it here cause this process is called before all
      store.commit('user/setToken', token)
      store.commit('cart/updCartId', cartId)

      MagentoSetToken(token)

      global.$('html').attr('data-class', '')
      global.$('.whole-navigation .main-navigation').removeAttr('use-style-color').removeAttr('style')
      global.$('body').trigger('windowGuard:resize:off')

      if (to.name === 'thankyou') {
        thankyouPageConstrain(store)
      }

      // close right panel if open
      store.commit('ui/setActiveComponent', {})
      store.commit('ui/showSearch', false)

      if (!whiteList.includes(to.name)) {
        if (cartId) {
          store.dispatch('cart/syncServerCart', {})
        } else {
          store.dispatch('cart/empty', null)
        }
      }

      if (Config.Theme.externalCheckout && ['checkout'].includes(to.name)) {
        location.href = externalCheckoutUrl(token, cartId, store.state.ui.storeViewCode)
      }
    }

    // call checkInventory to ask products avaibility to argo
    if (from.name === 'cart' && to.name === 'checkout') {
      const store = global.$store
      store.commit('ui/showLoader')

      let cartItems = store.state.cart.items
      let country = (store.state.ui.currentCountry.country).toLowerCase()
      let barcode = {}
      cartItems.forEach(element => {
        barcode[element.extension_attributes.ean] = element.qty
      })
      return store.dispatch('checkout/checkinventory', { country, barcode })
        .then(resp => {
          if (!resp.available) {
            next({ name: 'cart', query: { checkInventory: 'true' } })
          } else {
            next()
          }
        })
        .finally(() => {
          store.commit('ui/hideLoader')
          next()
        })
    } else {
      next()
    }
  })

  return router
}

function thankyouPageConstrain (store) {
  store.dispatch('cart/empty')
}
