import Config from '@/config'

const attrArr = JSON.stringify(Config.Theme.attributesToload)

/**
 * searchGraph = filter: {
            color: {eq: "5477"},
            size: {eq: "5593"},
            category_id: {in: ["11","12","13","14","15","16","17","18","19"]}
        },
        currentPage: 1,
        pageSize: 9,
        sort: { position: DESC }
 */
const environmentFieldGeneral = `
  master_sku
  sibling_products {
    url_key
    url_suffix
    color
    color_label
    color_image
  }
`

/* const environmentFieldConfigurable = `
  translations(attribute_code: "size"){
    label
    zone
  }
` */

export default `query Products(
      $search: String
      $currentPage: Int
      $filter: ProductAttributeFilterInput
      $pageSize: Int
      $sort: ProductAttributeSortInput,
      $id: Int
    ) {
    category (id: $id) {
      name
      display_mode
      description
      cms_block{
        title
        identifier
      }
      breadcrumbs{
        category_id
        category_url_path
        category_url_key
        category_is_clickable
        category_level
        category_id
        category_name
      }
      canonical_url
      custom_canonical_link
      href_lang_links{href, hreflang}
      meta_description
      meta_title
      meta_keywords
      meta_robots
      head_content_page
    }
    products(
      search: $search
      currentPage: $currentPage
      filter: $filter
      pageSize: $pageSize
      sort: $sort ) {
      items {
        id
        home_product
        set
        one_size
        sku
        name
        size
        stock_status
        canonical_url
        url_suffix
        url_key
        color
        color_label
        color_image
        is_exclusive_online
        ${process.env.VUE_APP_ORIGIN === 'ONLINE' ? environmentFieldGeneral : ''}
        categories{
          breadcrumbs{
            category_name
            category_url_path
          }          
        }
        new_from_date
        new_to_date
        out_of_stock_threshold
        tax_rate
        aqa_hero
        attributesLabel (attributes_identifier: ${attrArr}) {
          label
          value
          attribute_id
          attribute_code
        }
        description { html }
        short_description { html }
        special_price
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value,
              currency
          }
          quantity
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
            fixed_product_taxes {
              amount {
                value
              }
              label
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
        special_from_date
        special_to_date
        image{
          label
          url
        }
        media_gallery {
          position
          disabled
          label
          url
          ...on ProductVideo {
            position
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        media_gallery_entries {
          types
          file
        }
        ... on AwGiftCardProduct {
          aw_gc_allow_delivery_date
          aw_gc_allow_open_amount
          aw_gc_amounts
          aw_gc_custom_message_fields
          aw_gc_days_order_delivery
          aw_gc_description
          aw_gc_email_templates {
            image_url
            name
            value
          }
          aw_gc_expire
          aw_gc_open_amount_max
          aw_gc_open_amount_min
          aw_gc_pool
          aw_gc_type
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            attribute_id
            values {
              value_index
              label
              default_label
              store_label
              translations(attribute_code: "size"){
                label
                zone
              }
            }
          }
          variants {
            product {
              special_from_date
              special_price
              special_to_date
              price_tiers {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value,
                    currency
                }
                quantity
              }
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                },
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                }
              }
            }
          }
        }
      }
      total_count
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      aggregationsStore {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      sort_fields{
        default
        options{
          label,
          value
        }
      }
    }
  }`
