import moment from 'moment'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

export function formatAddress (addrr) {
  return `${addrr.firstname} ${addrr.lastname}, ${addrr.street.join(' ')}, ${addrr.city}, ${addrr.region.region_code} ${addrr.postcode} ${addrr.country_id}`
}

/* prevent scrolling on ios */
let scrollPosition = 0

export function enablePreventScrollingSafari () {
  const $body = document.querySelector('body')
  scrollPosition = window.pageYOffset
  $body.style.overflow = 'hidden'
  $body.style.position = 'fixed'
  $body.style.top = `-${scrollPosition}px`
  $body.style.width = '100%'
}

export function disablePreventScrollingSafari () {
  const $body = document.querySelector('body')
  $body.style.removeProperty('overflow')
  $body.style.removeProperty('position')
  $body.style.removeProperty('top')
  $body.style.removeProperty('width')
  window.scrollTo(0, scrollPosition)
}

// mageworx boutique working hours
export function isOpenNow (workingHours) {
  if (workingHours) {
    let today = moment()
    if (workingHours['everyday']) {
      return {
        // Convert AM|PM to 24h to compare
        isOpen: moment(today).format('HH:mm') <= moment(workingHours['everyday'].to, 'hh:mm A').format('HH:mm'),
        closeAt: workingHours['everyday'].to,
        openAt: workingHours['everyday'].from
      }
    } else {
      for (let i in workingHours) {
        // look for today
        if (i === today.isoWeekday()) {
          return {
            // Convert AM|PM to 24h to compare
            isOpen: moment(today).format('HH:mm') <= moment(workingHours[i].to, 'hh:mm A').format('HH:mm'),
            closeAt: workingHours[i].to,
            openAt: workingHours[i].from
          }
        }
      }
    }
  }
}

export function removeProductMediaByTag ({ tags, mediaGalleries, mediaEntries }) {
  let arraytags = tags.split(',')
  // let newImages = []
  // let files = []
  let images = cloneDeep(mediaGalleries)
  arraytags.forEach((tag) => {
    mediaEntries.forEach((gallEntry, index) => {
      if (gallEntry.types.includes(tag) || gallEntry.types.find(type => type.includes(tag))) {
        remove(images, (img) => {
          return img.url.includes(gallEntry.file)
        })
      }
    })
  })
  return images
}

export function getProductMediaByTag ({ tag, mediaEntries }) {
  return findIndex(mediaEntries, (gallEntry) => {
    return gallEntry.types.includes(tag)
  })
}
