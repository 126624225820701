export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'ui/setCountry':
        if (process.client) {
          let country = state.ui.currentCountry && state.ui.currentCountry.country ? state.ui.currentCountry.country : null
          global.$cookies.set('geolocator:country', country)
        }
        break
      case 'ui/setStateArea':
        if (process.client) {
          global.$cookies.set('geolocator:state', state.ui.currentState)
        }
        break
      case 'ui/sizeCountrySelected':
        if (process.client && window.sessionStorage) {
          window.sessionStorage.setItem('sizeCountrySelected', state.ui.sizeCountrySelected)
        }
        break
    }
  })
}
