export default {
  messages: {
    required: '必填项目',
    requireTrue: '必填项目',
    phoneNumber: '无效的电话号码',
    alpha: '只输入字母字符',
    email: '不合规电邮',
    min: (fieldName, params) => `${fieldName} 必须至少有 ${params[0]} 个字符`,
    confirmed: (fieldName) => `${fieldName} 与输入的不匹配`,
    numeric: '仅限数值',
    length: '长度不正确'
  }
}
