<template>
  <div>
    <b-modal
      modal-class="dynamic-modal"
      dialog-class="dynamic-modal__dialog"
      :content-class="getContentClass"
      :header-class="getHeaderClass"
      body-class="dynamic-modal__dialog__content__body"
      footer-class="dynamic-modal__dialog__content__footer"
      v-bind="getPropsBootstrapVueModal"
      v-model="showModal"
      @hidden="onCloseModal">
      <component :class="[dynamicClass]" :is="getComponent" v-bind="getProps"></component>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/helpers'
export default {
  name: 'DynamicModal',
  computed: {
    showModal: {
      get () {
        return Object.keys(this.$store.getters['ui/getDyamicModalComponent']).length > 0
      },
      set (newValue) {
        return newValue
      }
    },
    getComponent () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.component) {
        return observer.component
      } else {
        return ''
      }
    },
    getPropsBootstrapVueModal () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.propsBootstrapVueModal) {
        return observer.propsBootstrapVueModal
      }
      return {}
    },
    getProps () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.props) {
        return observer.props
      }
      return {}
    },
    dynamicClass () {
      let classObj = {}
      if (this.getComponent && this.getComponent.name) {
        classObj = {
          [`dynamic-modal__component--${this.getComponent.name.toLowerCase()}`]: true
        }
      }
      return classObj
    },
    getContentClass () {
      if (this.getPropsBootstrapVueModal && this.getPropsBootstrapVueModal['content-class']) {
        return this.getPropsBootstrapVueModal['content-class']
      }
      return 'dynamic-modal__dialog__content'
    },
    getHeaderClass () {
      if (this.getPropsBootstrapVueModal && this.getPropsBootstrapVueModal['header-class']) {
        return this.getPropsBootstrapVueModal['header-class']
      }
      return 'modal__dialog__content__header'
    }
  },
  methods: {
    onCloseModal () {
      this.$store.commit('ui/setDynamicModalComponent', {})
    }
  },
  mounted () {
    EventBus.$on('dynamicModal:close', this.onCloseModal)
  }
}
</script>
