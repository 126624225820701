<template>
  <router-link :to="product.to" @click.native="$emit('resetSearch')" class="text-decoration-none">
    <article class="card-product">
      <div class="card-product__media">
        <image-gallery
        :hasVideo="hasVideo ? hasVideo.video_content.video_url : ''"
        :imgPath="imgObj.src"
        :alt="product.image.label"
        :src="imgObj.loading"
        size="LG"
        :changeAble="false"/>
      </div>
      <div class="article_name">
        <h2 class="card-product__info__intro__link__name">{{product.name}}</h2>
        <span v-if="product.aqa_sottotitolo" class="card-product__info__intro__link__subtitle">
          {{product.aqa_sottotitolo}}
        </span>
      </div>
      <div class="card-product__info__price">
        <product-price :product="productSelected"></product-price>
      </div>
      <div class="card-product__info__attributes" v-if="showSpecial_price">
        <span class="f-semibold cl-black text-uppercase t-small">{{ $t('Special_price') }}</span>
      </div>
      <div class="card-product__info__attributes" v-else-if="showExclusive_online">
        <span class="f-semibold cl-black text-uppercase t-small">{{ $t('Exclusive_online') }}</span>
      </div>
      <div class="card-product__info__attributes" v-else-if="showNewIn">
        <span class="f-semibold cl-black text-uppercase t-small">{{ $t('New in') }}</span>
      </div>
    </article>
  </router-link>
</template>

<script>
import ProductTile from '@/theme/components/Listing/ProductTile.vue'

export default {
  name: 'SearchResultItem',
  mixins: [ProductTile],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  created () {
    try {
      // Hotfix OOS
      if (this.product.price_range.minimum_price.regular_price.value === 0) {
        this.product.price_range.minimum_price = this.product.price_range.maximum_price
      }
    } catch (err) {
      console.log(err)
    }
  }
}
</script>
