export default `
  query GetPost($id: String) {
    blogPost(id: $id) {
      post_id
      meta_title
      meta_description
      filtered_content_ext
      related_posts{
        identifier
        post_id
        title
        content_heading
        short_content
        first_image
        is_active
        publish_time
        categories{
          title
          identifier
        }
      }
    }
  }`
