export default `query orderTemplate ($id: String, $email: String){
  orderTemplate (id: $id, email: $email){
    order_id
    order_number
    customer_name
    customer_email
    items {
      item_sku
      item_name
      image_url
      qty_available
      order_item_id
      product {
        home_product
        image {
          url
        }
      }
    }
    shipping_info{
      firstname
      lastname
      street
      city
      region
      postcode
      country_id
      telephone
    }
    dictionary {
      conditions{
        value
        label
      }
      reasons{
        value
        label
      }
      resolutions{
        value
        label
      }
    }
  }
}`
