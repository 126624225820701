export default {
  messages: {
    required: 'Pflichtfeld',
    requireTrue: 'Pflichtfeld',
    phoneNumber: 'ungültige Telefonnummer',
    alpha: 'Geben Sie nur Buchstaben ein',
    email: 'ungültige E-Mail',
    min: (fieldName, params) => `die ${fieldName} muss mindestens ${params[0]} Zeichen lang sein`,
    confirmed: (fieldName) => `Die ${fieldName} stimmt nicht mit der eingegebenen überein`,
    numeric: 'nur Zahlenwerte',
    length: 'die Länge ist falsch'
  }
}
