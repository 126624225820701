import Config from '@/config'
import { find } from 'lodash'

export const DefaultAddress = {
  firstname: '',
  lastname: '',
  street: [],
  city: '',
  region: { region: '', region_code: '', region_id: 0 },
  postcode: '',
  country_id: '',
  telephone: '',
  default_shipping: false,
  default_billing: false,
  useotheraddress: false
}

export const DefaultShipping = {
  ...DefaultAddress,
  shippingMethod: Config.Theme.default.shippingMethod,
  shippingMethodAdditional: {}
}

export const DefaultBilling = {
  ...DefaultAddress,
  default_billing: false,
  default_shipping: false,
  company: '',
  vat_id: ''
}

export function addressAssest (address) {
  address.region = {
    region: address.region.code,
    region_code: address.region.code,
    region_id: address.region.region_id
  }
  address.country_id = address.country.code
  address.street = trimStreet(address.street)
  return address
}

export function trimStreet (street) {
  let newStr = []
  if (street && street.length) {
    street.forEach(addrr => {
      if (addrr) {
        newStr.push(addrr)
      }
    })
  } else {
    newStr = street
  }
  return newStr
}

export function getCountry (currentCountry, countryAvailable, $store) {
  if (countryAvailable.length) {
    const countryBase = $store.state.ui.currentCountry ? $store.state.ui.currentCountry.country : ''
    if (find(countryAvailable, { id: currentCountry })) {
      return currentCountry
    } else if (find(countryAvailable, { id: countryBase })) {
      return countryBase
    } else {
      return countryAvailable[0].id
    }
  }
  return ''
}
