export default class Meta {
  $store = {}

  constructor ($store) {
    this.$store = $store
  }

  getTitle (metaTitle) {
    return metaTitle || this.$store.getters['storeConfig/metaTitle']
  }

  getDescription (metaDescription) {
    return metaDescription || this.$store.getters['storeConfig/metaDescription']
  }

  getKeyword (metaKeyword) {
    return metaKeyword || this.$store.getters['storeConfig/metaKeyword']
  }

  getHrefLang () {
    return this.$store.state.ui.hrefLang
  }

  getRobots () {
    // return process.env.VUE_APP_INDEX
    let robotText = this.$store.state.ui.metaRobots || process.env.VUE_APP_INDEX
    return robotText
  }

  getJsonLD () {
    let jsonStruArr = []
    // markup site
    if (this.$store.state.storeConfig.config.seo_markup_website) {
      jsonStruArr.push(this.$store.state.storeConfig.config.seo_markup_website)
    }
    if (this.$store.state.storeConfig.config.seo_markup_seller &&
        this.$store.state.storeConfig.config.seo_markup_seller.show === 'all_pages' &&
        this.$store.state.storeConfig.config.seo_markup_seller.json) {
      jsonStruArr.push(this.$store.state.storeConfig.config.seo_markup_seller.json)
    }
    if (this.$store.state.ui.jsonLD && this.$store.state.ui.jsonLD.length) {
      jsonStruArr = jsonStruArr.concat(this.$store.state.ui.jsonLD)
    }
    return jsonStruArr
  }

  getCanonical () {
    return this.$store.state.ui.canonical
  }

  getBreadcrumb () {
    return this.$store.state.ui.breadcrumb
  }
}
