export default `query storeConfig{
  storeConfig {
    id
    code
    store_name
    locale
    root_category_id
    base_url
    base_link_url
    base_media_url
    base_static_url
    base_currency_code
    category_url_suffix
    cms_home_page
    copyright
    default_title
    default_keywords
    default_description
    front
    product_url_suffix
    website_id
    bss_catalog_permission {
      enabled
      redirect_page
      use_parent_permission
    }
    newsletterSettings {
      modal_media_url
      modal_media_url_mobile
      page_media_url
      page_media_url_mobile
    }
    seo_markup_seller {
      json
      show
    }
    seo_markup_speakable {
      json_category
      json_product
      json_page
    }
    seo_markup_website
    seo_markup_search_action
    paymentMethodSettings {
      adyenConfig {
        client_key_live
        client_key_test
        live_endpoint_prefix
        merchant
        mode
      }
      paypalConfig {
        merchant
        mode
      }
    }
  }
}`
