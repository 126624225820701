<template>
  <ul :class="[`sublevel${level}`]">
    <slot name="first-of-list" />
    <li :class="[`sublevel${level}`]" v-for="subDeep in cats" :key="subDeep.url_path">
      <slot name="list-level" v-bind="subDeep">
        <link-manager :category="subDeep" :label="subDeep.nameKey ? $t(subDeep.nameKey) : subDeep.name" class="nav-sublink bcm-link bcm-link-secondary bcm-link-noupper" @click.native="$emit('linkClicked')" />
      </slot>
    </li>
    <slot name="last-of-list" />
  </ul>
</template>

<script>
import sortBy from 'lodash/sortBy'
import LinkManager from './LinkManager'

export default {
  name: 'SubMenuByLevel',
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => []
    },
    level: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  computed: {
    cats () {
      return sortBy(this.categories, ['position'])
    }
  },
  components: {
    LinkManager
  }
}
</script>
