export default {
  messages: {
    required: 'campo requerido',
    requireTrue: 'campo requerido',
    phoneNumber: 'numero de telefono invalido',
    alpha: 'ingrese solo caracteres alfabéticos',
    email: 'correo electrónico no válido',
    min: (fieldName, params) => `el ${fieldName} debe tener al menos ${params[0]} caracteres`,
    confirmed: (fieldName) => `El ${fieldName} no coincide con el ingresado`,
    numeric: 'solo valores numéricos',
    length: 'la longitud es incorrecta'
  }
}
