import _ from 'lodash'

export default {
  isLogged (state) {
    return !!state.token
  },
  hasAddresses (state) {
    return state.current && state.current.addresses.length > 0
  },
  getWishlistItems (state) {
    return (state.current && state.current.wishlist && state.current.wishlist.items) ? state.current.wishlist.items : []
  },
  getGroupId (state) {
    return (state.current && state.current.group_id) ? state.current.group_id : null
  },
  isOnWishlist: (state) => (product) => {
    let singleProduct = {}
    if (_.isArray(product) && product.length) {
      singleProduct = product[0]
    } else if (_.isObject(product)) {
      singleProduct = product
    }
    let current = state.current
    let match = false
    if (current && current.wishlist && current.wishlist.items.length) {
      match = _.find(current.wishlist.items, item => item.sku === singleProduct.sku)
    }
    return match
  },
  getCustomer (state) {
    return state.current || {}
  }
}
