<template>
  <b-col md="9" xl="7" class="mx-auto subcat-navigation__list with-title">
    <b-row>
      <b-col cols="5">
        <ul class="column-navigation">
          <li class="navbar-item sublevel1" v-for="subcategory in orderedCategory" :key="subcategory.url_path">
            <link-manager :category="subcategory" :label="subcategory.nameKey ? $t(subcategory.nameKey) : subcategory.name" class="nav-sublink bcm-link bcm-link-secondary bcm-link-noupper" @click.native="linkClicked" />
            <div class="sublevel2 sublock-wrapper" v-show="subcategory.childrenData && subcategory.childrenData.length">
              <sub-menu-by-level :level="2" :categories="subcategory.childrenData" @linkClicked="linkClicked" />
            </div>
          </li>
        </ul>
      </b-col>
      <b-col cols="7" class="subcat-navigation__list_medias">
        <NavImage :category="category" />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import NavImage from '../NavImage'
import SubMenuByLevel from '@/theme/components/Header/SubMenu/Layout/SubMenuByLevel'
import LinkManager from './LinkManager'
import { EventBus } from '@/helpers'

export default {
  name: 'WithTitle',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderedCategory () {
      return sortBy(filter(this.category.childrenData, { include_in_menu: true, is_active: true }), ['position'])
    }
  },
  methods: {
    linkClicked () {
      EventBus.$emit('category:change', true)
      window.pagePosition = null
    }
  },
  components: {
    NavImage,
    SubMenuByLevel,
    LinkManager
  }
}
</script>
