import _ from 'lodash'

export function init () {
  if (process.client) {
    global.$('body').on('widget:half-image-with-text-video', (event, placeholder) => {
      const elIdentifier = placeholder.elIdentifier
      let $ref = global.$(placeholder.elIdentifier)
      if (!$ref.hasClass('vjs-user-active')) {
        let settings = {}
        settings = global.$(elIdentifier).data()
        _.forIn(settings, function (value, key) {
          key = _.camelCase(key)
        })
        if (settings) {
          let player = global.videojs.videojs(elIdentifier, {
            autoplay: settings.options.autoplay,
            loop: settings.options.loop,
            muted: settings.options.muted,
            controls: settings.options.controls,
            sources: settings.options.sources
          }, function () {
            if (settings.options.autoplay) {
              player.autoplay(true)
            } else {
              player.bigPlayButton.show()
            }
          })
        }
      }
    })
  }
}
