import { initDomClasses } from '../windowGuard/resize'
import $ from 'jquery'

function checkForMenuColor (identifier, isHero) {
  // if slide is in hero mode
  // check if slide inside has tag to color the menu
  let menuColor = $(identifier).data('menu-color')
  if (isHero) {
    if (menuColor) {
      $('.whole-navigation .main-navigation').attr('use-style-color', true).css({ 'color': menuColor, 'fill': menuColor })
    } else {
      $('.whole-navigation .main-navigation').removeAttr('use-style-color').removeAttr('style')
    }
  }
}

export function init () {
  if (process.client) {
    $('body').on('widget:dobleImage', (event, placeholder) => {
      if (placeholder.isHero) {
        initDomClasses()
      }
      checkForMenuColor(placeholder.widgetIdentifier, placeholder.isHero)
    })
  }
}
