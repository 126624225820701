import Graphql from '@/services/Graphql'
import Logger from '@/services/Logger'
import Config from '@/config'
import _ from 'lodash'
import filter from 'lodash/filter'
import { pathTranslate } from '@/helpers/routeHelpers'

class Blog {
  // constructor () { }
  decorateCategories (category, token, storeViewCode) {
    const _this = this
    if (category && category.items) {
      const indexBlogInCat = this.findBlogInCategories(category.items, storeViewCode)
      if (indexBlogInCat.length) {
        return this.getCategories(token, storeViewCode).then(blogCategories => {
          for (let i in indexBlogInCat) {
            _this.attachBlogCategory(blogCategories, category.items, indexBlogInCat[i])
          }
          return category
        })
      }
    }
    return Promise.resolve(category)
  }

  getCategories (token, storeViewCode) {
    return Graphql.genericCallCash({
      token, // token
      storeViewCode, // storeViewCode
      graphSchemaType: 'blogCategories'
    })
      .then(resp => {
        return resp && resp.data && resp.data.blogCategories ? resp.data.blogCategories.items : []
      })
      .catch(err => {
        Logger.error(err)
        return []
      })
  }

  isCategoryBlog (category, urlKey) {
    return category.custom_attributes.find(item => {
      let isBlog = item.attribute_code === 'url_key' && item.value === urlKey
      if (isBlog) {
        category.blogHelpers = {
          [item.attribute_code]: item.value,
          identifier: urlKey
        }
      }
      return isBlog
    })
  }

  findBlogInCategories (category, storeview) {
    const index = []
    let urlKey = pathTranslate({ path: 'blog', storeview })
    for (let i in category) {
      if (category[i].is_active && category[i].include_in_menu && this.isCategoryBlog(category[i], urlKey)) {
        index.push(i)
      }
    }
    return index
  }

  attachBlogCategory (blogCategories, categories, index) {
    let blogIndex = categories[index].blogHelpers['identifier']
    let blogCatByIndex = blogCategories.find(cat => cat.identifier === blogIndex)
    if (blogCatByIndex) {
      categories[index].blogHelpers.id = blogCatByIndex.category_id
      categories[index].blogHelpers.identifier = blogCatByIndex.identifier
      // attach link to all
      categories.push({
        ...categories[index]
      })
      categories[index].children = this.getChildIds(blogCatByIndex.category_id, blogCategories)
      // findAnd Attach child
      for (let i in blogCategories) {
        if (blogCategories[i].path && parseInt(blogCategories[i].path) === blogCatByIndex.category_id) {
          categories.push(this.createCategoryByBlog(blogCategories[i], categories[index], blogCategories))
        }
      }
    }
  }

  createCategoryByBlog (blogCat, catParent, blogCats) {
    let urlKey = catParent && catParent.blogHelpers ? catParent.blogHelpers.url_path : ''
    let category = {
      available_sort_by: [],
      children: this.getChildIds(blogCat.category_id, blogCats),
      childrenData: [],
      custom_attributes: [{
        attribute_code: 'is_clickable',
        value: 1
      }],
      custom_layout: blogCat.custom_layout,
      featured_img: blogCat.featured_img,
      blog_category_id: blogCat.category_id,
      blog_category_parent_id: blogCat.parent_category_id,
      id: this.generateCategoryId(blogCat.category_id),
      include_in_menu: !!blogCat.include_in_menu,
      is_active: !!blogCat.is_active,
      name: blogCat.title,
      parent_id: catParent.id,
      path: `${catParent.path}/${blogCat.path}`,
      position: blogCat.position,
      meta_description: blogCat.meta_description,
      meta_keywords: blogCat.meta_keywords,
      meta_title: blogCat.meta_title,
      blogHelpers: {
        id: blogCat.category_id,
        category_parent_id: blogCat.parent_category_id,
        identifier: blogCat.identifier
      }
    }
    // catParent.children = catParent.children && catParent.children.length ? catParent.children + ',' + category.id : '' + category.id
    // add path
    const urlPathIndex = catParent.custom_attributes.findIndex(item => item.attribute_code === 'url_path')
    if (urlPathIndex) {
      if (urlKey) {
        catParent.custom_attributes[urlPathIndex].value = urlKey
      }
      category.custom_attributes.push({
        attribute_code: 'url_path',
        value: catParent.custom_attributes[urlPathIndex].value + '/' + blogCat.identifier
      })
    } else {
      category.custom_attributes.push({
        attribute_code: 'url_path',
        value: blogCat.identifier
      })
    }

    return category
  }

  getChildIds (id, allBlogCats) {
    return filter(allBlogCats, { parent_category_id: id }).map((cat) => this.generateCategoryId(cat.category_id)).join(',')
  }
  generateCategoryId (currentId) {
    return 2000 + currentId
  }

  getCategory (category, token, storeViewCode) {
    return Graphql.genericCallCash({
      token, // token
      storeViewCode, // storeViewCode
      graphSchemaType: 'blogCategory',
      variables: {
        'categoryId': '' + category.blogHelpers.id
      }
    })
      .then(resp => {
        if (resp && resp.data && resp.data.errors) {
          throw resp.data.errors
        }
        return resp && resp.data && resp.data.blogCategory ? resp.data.blogCategory : null
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  getPost (slug, token, storeViewCode) {
    let identifier = slug.split('/')
    identifier = identifier[identifier.length - 1]
    return Graphql.genericCallCash({
      token, // token
      storeViewCode, // storeViewCode
      graphSchemaType: 'blogPost',
      variables: {
        'id': identifier
      }
    })
      .then(resp => {
        if (resp && resp.data && resp.data.errors) {
          throw resp.data.errors
        }
        return resp && resp.data && resp.data.blogPost ? resp.data.blogPost : null
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  getPosts ({ filter, token, storeViewCode }) {
    let identifier = filter.filters.category_id.eq.split('/')
    identifier = identifier[identifier.length - 1]
    filter.filters.category_id.eq = identifier
    return Graphql.genericCallCash({
      token, // token
      storeViewCode, // storeViewCode
      graphSchemaType: 'blogPosts',
      variables: filter
    })
      .then(resp => {
        return resp && resp.data && resp.data.blogPosts ? resp.data.blogPosts : null
      })
      .catch(err => {
        Logger.error(err)
        return null
      })
  }

  getBreadcrumb ({ Vue, $store, $route, $router, title }) {
    let paths = [{
      text: Config.Theme.name,
      to: Vue.filter('localizedByNameCategories')('home', null, null, $store, $router),
      exact: true
    }]
    let parentCategory = $store.state.cms.blogParentCat
    if (parentCategory) {
      paths.push({
        text: parentCategory.name,
        to: `/${$store.state.ui.storeViewCode}/${parentCategory.url_path}.html`,
        active: !$route.params.cat
      })
      if ($route.params.cat) {
        let catIdentifier = $route.params.cat.replace('.html', '')
        let childCategory = _.find(parentCategory.childrenData, { url_path: `${parentCategory.url_path}/${catIdentifier}` })
        if (childCategory) {
          paths.push({
            text: childCategory.name,
            to: `/${$store.state.ui.storeViewCode}/${parentCategory.url_path}/${$route.params.cat}.html`,
            active: !title
          })
        }
        if (title) {
          paths.push({
            text: title,
            to: '#',
            active: true
          })
        }
      }
    }
    return paths
  }
}

const classSingleton = new Blog()

export default classSingleton
