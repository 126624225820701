<template>
  <div class="product-bk">
    <div class="image-behind">
      <img :src="imageBehind" class="img-placeholder">
    </div>
    <div v-if="video.length">
      <video autoplay playsinline loop muted class="aqz-video">
        <source :src="video" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div v-else>
      <div v-lazy:background-image="imageCropped" class="background-above"></div>
    </div>
  </div>
</template>

<script>
import Config from '@/config'
import { getProductMediaByTag } from '@/theme/helpers'

export default {
  name: 'BackgroundMedia',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    heroIndex () {
      return getProductMediaByTag({ tag: Config.Theme.heroProductIdentifier, mediaEntries: this.product.media_gallery_entries })
    },
    imageBehind () {
      const { width, height } = Config.Theme.listingOpt.imageCrop
      return `${this.product.image.url}?fit=crop&w=${width}&h=${height}`
    },
    video () {
      // if product has video show it
      // otherwise show image
      let video = this.product.media_gallery.find((gallery) => gallery.hasOwnProperty('video_content'))
      if (video && this.heroIndex !== -1) {
        video = this.product.media_gallery[this.heroIndex].video_content.video_url
      }
      return video || ''
    },
    imageCropped () {
      // get indexOf media types 'media_hero'
      let imageUrl = ''
      if (this.heroIndex !== -1) {
        imageUrl = this.product.media_gallery[this.heroIndex].url
      } else {
        imageUrl = this.imageBehind()
      }
      return imageUrl
    }
  }
}
</script>
