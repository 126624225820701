<template>
  <button class="hamburger hamburger--collapse" type="button" @click.prevent="$emit('click', $event)">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'HamburgerButton'
}
</script>
