import Config from '@/config'
// import Magento from '@/services/Magento'
import Bus from '@/services/Bus'

const Newsletter = {
  subscribeCustomer (storeViewCode, email, token, privacyTerms, profilingTerms) {
    let query = Config.API.newsletter.subscribeCustomer
    return Bus.post(query, {
      params: {
        storeViewCode: storeViewCode,
        email: email,
        token,
        privacyTerms,
        profilingTerms
      }
    })
      .then(res => {
        return res
      })
      .catch(err => {
        let e = err
        return e
      })
  }
}
export default Newsletter
