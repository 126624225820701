import { EventBus } from '@/helpers'
import { mobileDetector, getCurrentPrice, getProductCategory as getProductCategoryHelper } from '@/helpers/utils'
import Logger from '@/services/Logger'
import { filter, sortBy, find, get } from 'lodash'
import Config from '@/config'
// import subscriber from './subscriber'

const trackListener = [
  'track:init',
  'track:addToCart',
  'track:clickProduct',
  'track:searchSuggestion',
  'track:productDetail',
  'track:purchase',
  'track:productList',
  'track:checkout',
  'track:cart',
  'track:deleteProduct'
]
class Tracker {
  constructor ({ store, gtm, app, router }) {
    this.store = store
    this.gtm = gtm
    this.app = app
    this.router = router
    this.firstCall = true
    this.activeListener()
  }
  evtTofx (evtName) {
    return evtName.replace('track:', '')
  }
  activeListener () {
    const _this = this
    trackListener.forEach(track => {
      EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this))
    })
    // listener route change
    this.router.afterEach((to, from) => {
      // first time wait user load
      if (!_this.firstCall) {
        Logger.debug('trackEvent:GTM:afterEach')
        _this.init()
      }
    })

    Logger.debug('trackEvent:GTM:activeListener')
  }

  iCanTrack () {
    /* Logger.debug('check track GTM', global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : false)
    return global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : false */
    return true // request on TICKET AQ-360
  }

  removeListener () {
    trackListener.forEach(track => {
      EventBus.$off(track)
    })
  }

  /**
   * check if is Mobile, tablet or Desktop
   */
  getChannel () {
    // m, t, d
    const _mobileDetector = mobileDetector()
    if (_mobileDetector) {
      if (_mobileDetector.mobile()) {
        return 'm'
      }
      if (_mobileDetector.tablet()) {
        return 't'
      }
      return 'd'
    }
    return 'not found'
  }

  getCountry () {
    return global.$cookies.get('geolocator:country')
  }

  getTemplateName () {
    let metaTpl = this.app.$route.meta.tplName
    if (metaTpl === 'factoryPage') {
      const currentComponent = this.store.state.cms.currentComponent
      if (currentComponent === 'ProductPage') {
        metaTpl = 'product'
      } else if (currentComponent === 'CmsPage') {
        metaTpl = 'other_generic'
      } else if (currentComponent === 'CategoryPage') {
        if (this.store.state.ui.breadcrumb.length > 2) {
          metaTpl = 'category'
        } else {
          metaTpl = 'category_main'
        }
      }
    }
    metaTpl = metaTpl || 'other_generic'
    return metaTpl
  }

  /**
   * ------TRACK INIT
   */
  init (action) {
    if (action === 'consent:true') {
      return false
    }
    if (this.iCanTrack()) {
      this.gtm.enable(true)
      // Logger.debug('trackEvent:GTM:init')
      // this.trackPage()
    }
    this.firstCall = false
  }

  // rimozione richiesta dal reparto marketing triboo -> Andrea Delle Donne

  // trackPage () {
  //   switch (this.getTemplateName()) {
  //     case 'category_main':
  //     case 'category':
  //       const currentCat = this.store.state.cms.currentComponent === 'CategoryPage' ? this.store.state.category.current : null
  //       this.gtm.trackEvent({
  //         ecommerce: {
  //           currencyCode: this.store.state.storeConfig.config.base_currency_code,
  //           impressions: this.getListProducts(currentCat)
  //         }
  //       })
  //       break
  //     case 'product':
  //       const currentProduct = this.store.state.cms.currentComponent === 'ProductPage' ? this.store.state.product.current : null
  //       const priceInfo = currentProduct ? getCurrentPrice(currentProduct) : null
  //       this.gtm.trackEvent({
  //         ecommerce: {
  //           detail: {
  //             products: [{
  //               id: currentProduct ? currentProduct.id : '',
  //               name: currentProduct ? currentProduct.name : '',
  //               price: this.formatPrice(priceInfo ? priceInfo.current : 0)
  //             }]
  //           }
  //         }
  //       })
  //       break
  //     case 'funnel_confirmation':
  //       this.setOrderVal()
  //       break
  //   }
  // }

  searchSuggestion (inputText) {
    if (inputText.inputText) {
      this.gtm.trackView('search', window.location.href + '?q=' + inputText.inputText)
    }
  }

  getListProducts (currentCat) {
    let products = []
    if (currentCat) {
      let productsState = this.store.state.listing.list
      for (let i = 0; i < productsState.length; i++) {
        products.push(this.getInfoListProduct(productsState[i]))
      }
    }
    return products
  }

  getInfoListProduct (product) {
    return {
      'id': product.id,
      'name': product.name, // Name or ID is required.
      'price': getCurrentPrice(product).current // product all tax included
    }
  }

  formatPrice (price) {
    return parseFloat(price).toFixed(2)
  }

  sanitazeDataLayer () {
    // push element not pushed on page init
    const userId = global.$cookies.get('cid')
    window.dataLayer = window.dataLayer || []
    const { pageType, pageProdListing, pageProdCategory } = this.getPageInfo(this.app.$route.meta.pageType)
    const dataObj = {
      event: 'DLupdate',
      userId,
      pageType,
      pageProdListing, // first level category
      pageProdCategory // second level category
    }
    window.dataLayer.push(dataObj)
  }

  getPageInfo (pageType) {
    let category = this.store.state.category.current
    const firstLevelCat = category.name || null
    const secondLevelCat = this.findCatFromParent(category)
    let categories = [].concat(firstLevelCat, secondLevelCat)
    if (pageType === 'FactoryPage') {
      if (categories.includes('CORPORATE', 'corporate')) {
        pageType = 'Corporate'
      } else {
        pageType = this.store.state.cms.currentComponent
      }
    }
    return { pageType, pageProdListing: firstLevelCat, pageProdCategory: secondLevelCat }
  }

  findCatFromParent (category) {
    if (category.parent_id) {
      // current category is child, get parent
      let allCat = this.store.state.category.list
      let parentCat = find(allCat, { id: category.parent_id })
      if (parentCat) {
        return parentCat.name
      } else {
        return null
      }
    } else {
      return null
    }
  }

  getProductCategory (product, store) {
    if (product.first_category) {
      return product.first_category
    } else {
      return getProductCategoryHelper(product, store)
    }
  }

  productList () {
    if (!this.iCanTrack()) {
      return false
    }
    this.gtm.enable(true)

    let category = this.store.state.category.current.id
    if (Object.keys(category.length <= 0)) {
      category = find(this.store.state.category.flatted, { id: parseInt(this.store.state.category.categoryId) })
    }
    if (category) {
      const currencyCode = get(this.store, 'state.storeConfig.config.base_currency_code', undefined)
      const catName = category && category.name ? category.name : ''
      const categoryMeta = this.store.state.category.metaData
      if (categoryMeta.display_mode !== 'PAGE') {
        // is a categry with no product
        this.gtm.trackEvent({
          event: 'productList',
          ecommerce: {
            currencyCode,
            impressions: this.getListProducts(catName)
          }
        })
      }
    }
    this.sanitazeDataLayer()
  }

  getOrderTaxAmount (order) {
    let taxPercent = order.items[0].tax_percent || order.items[1].tax_percent
    let revenue = order.base_grand_total - (order.base_shipping_amount - order.base_shipping_discount_amount)
    let taxAmount = revenue - (revenue / (1 + taxPercent / 100))
    return taxAmount
  }

  purchase () {
    const order = this.store.state.orders.currentOrder
    // rimozione richiesta dal reparto marketing triboo -> Andrea Delle Donne
    // if (!(Object.keys(order).length)) {
    //   setTimeout(this.setOrderVal.bind(this), 500)
    //   return false
    // }
    if (Object.keys(order).length) {
      this.gtm.trackEvent({
        event: 'Purchase',
        ecommerce: {
          currencyCode: order.base_currency_code,
          purchase: {
            actionField: {
              id: order.increment_id,
              affiliation: Config.Theme.name,
              revenue: this.formatPrice(order.base_grand_total),
              tax: this.formatPrice(this.getOrderTaxAmount(order)),
              shipping: order.shipping_amount - order.shipping_discount_amount,
              coupon: order.coupon_code
            },
            products: this.getOrderProducts()
          }
        }
      })
    }
  }

  productDetail (product) {
    if (this.iCanTrack() && product) {
      const priceInfo = getCurrentPrice(product)
      this.gtm.enable(true)
      this.gtm.trackEvent({
        event: 'productDetail',
        ecommerce: {
          detail: {
            products: [{
              id: product ? product.id : '',
              name: product ? product.name : '',
              brand: Config.Theme.name,
              price: this.formatPrice(priceInfo ? priceInfo.current : 0),
              category: this.getProductCategory(product, this.store)
            }]
          }
        }
      })
    }
    this.sanitazeDataLayer()
    return false
  }

  checkout () {
    if (!this.iCanTrack()) {
      return false
    }
    this.pushCheckoutEvent(2, 'checkout')
  }

  cart () {
    if (!this.iCanTrack()) {
      return false
    }
    this.pushCheckoutEvent(1, 'cart')
  }

  pushCheckoutEvent (step, option) {
    this.gtm.trackEvent({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            'step': step,
            'option': option
          },
          products: this.getCheckoutProducts(this.store.state.cart.items)
        }
      }
    })
  }

  /**
   * Checkout Step 1/2
   * richiamare questo script quando l'utente atterra sulla pagina one step checkout
   */
  getCheckoutProducts (products) {
    return products.map(product => ({
      'name': product.name, // Name or ID is required.
      'id': product.extension_attributes ? parseInt(product.extension_attributes.product_id) : 'no product_id',
      'price': product.base_price_incl_tax, // product all tax included
      'quantity': product.qty,
      'variant': product.extension_attributes ? product.extension_attributes.sku : '',
      'brand': product.extension_attributes ? product.extension_attributes.family : '',
      'dimension27': product.extension_attributes ? product.extension_attributes.active_plan : 'No selection',
      'category': this.getProductCategory(product.extension_attributes, this.store)
    }))
  }

  getDetailProducts (parentProduct, products, type) {
    const _this = this
    if (parentProduct.product_links) {
      let sorter = parentProduct.product_links
      let related = filter(sorter, { link_type: (type === 'upsell products' ? 'upsell' : 'related') })
      let index = -1
      return filter(sortBy(products, function () {
        index++
        return related[index].position
      }), { enabled: true })
        .map((product, index) => ({
          'id': product.id,
          'price': getCurrentPrice(product).current,
          'name': product.name,
          'brand': Config.Theme.name,
          'category': this.getProductCategory(product, _this.store),
          'list': type,
          'position': ++index
        }))
    } else {
      return products
    }
  }

  /**
   * --------TRACK adToCart
   */
  addToCart ({ product, prodComplete }) {
    if (!this.iCanTrack()) {
      return false
    }
    /* if (!this.gtm.enable()) {
      return false
    } */
    this.gtm.trackEvent({
      event: 'addToCart',
      ecommerce: {
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        add: {
          products: [{
            'id': product ? product.item_id : prodComplete.item_id,
            'name': product ? product.name : prodComplete.name,
            'price': product ? this.formatPrice(product.price) : this.formatPrice(prodComplete.price_incl_tax),
            'quantity': product ? product.qty : prodComplete.qty
          }]
        }
      }
    })
  }
  /**
   * ----------Track click product from category page
   */
  clickProduct ({ product }) {
    if (!this.iCanTrack()) {
      return false
    }
    /* if (!this.gtm.enable()) {
      return false
    } */
    const priceInfo = getCurrentPrice(product)
    this.gtm.trackEvent({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: this.formatPrice(priceInfo ? priceInfo.current : 0)
            }
          ]
        }
      }
    })
  }
  /**
   * delete product
   */
  deleteProduct ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    /* if (!this.gtm.enable()) {
      return false
    } */
    this.gtm.trackEvent({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [{
            'id': product.item_id,
            'name': product.name,
            'price': this.formatPrice(product.price)
          }]
        }
      }
    })
  }
  /**
   * confirm order
   */
  /**
   * ---- TRACK setOrderVal
   */

  // rimozione richiesta dal reparto marketing triboo -> Andrea Delle Donne

  // setOrderVal () {
  //   /* if (!this.gtm.enable()) {
  //     return false
  //   } */
  //   const order = this.store.state.orders.currentOrder
  //   if (!(Object.keys(order).length)) {
  //     setTimeout(this.setOrderVal.bind(this), 500)
  //     return false
  //   }
  //   Logger.debug('trackEvent:GTM:setOrderVal')
  //   if (Object.keys(order).length) {
  //     debugger
  //     this.gtm.trackEvent({
  //       ecommerce: {
  //         currencyCode: order.base_currency_code,
  //         purchase: {
  //           actionField: {
  //             id: order.increment_id,
  //             affiliation: Config.Theme.name,
  //             revenue: this.formatPrice(order.base_subtotal_incl_tax),
  //             tax: order.tax_amount,
  //             shipping: order.shipping_amount,
  //             coupon: order.coupon_code
  //           },
  //           products: this.getOrderProducts()
  //         }
  //       }
  //     })
  //   }
  // }

  getOrderProducts () {
    const products = this.store.getters['orders/getProduct']
    return products.map(product => ({
      'id': product.product_id,
      'price': this.formatPrice(product.base_price_incl_tax),
      'name': product.name,
      'quantity': product.qty_ordered
    }))
  }
}

export default Tracker
