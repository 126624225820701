import _ from 'lodash'
import $ from 'jquery'
import { isPortrait } from '@/helpers/utils'

export function init () {
  if (process.client) {
    window.isPortrait = isPortrait
    let players = window.players = {}
    // let videoIdentifier = []
    $('body').on('widget:swiper-video', (event, placeholder) => {
      const elIdentifier = placeholder.elIdentifier
      // to do make map
      // let findPlayerIndex = videoIdentifier.findIndex(el => (el === elIdentifier))
      if (players[elIdentifier]) {
        let findInDom = $(document).find(players[elIdentifier].el_).length
        // sometimes in ssr mode
        // v-append-html directive update the dom silently
        // that's why check for element on the dom
        if (!findInDom) {
          players[elIdentifier].dispose()
          delete players[elIdentifier]
          // videoIdentifier.splice(findPlayerIndex, 1)
          // players.splice(findPlayerIndex, 1)
        }
      }
      if (!players[elIdentifier]) {
        let settings = {}
        settings = $(elIdentifier).data()
        _.forIn(settings, function (value, key) {
          key = _.camelCase(key)
        })
        let player = global.videojs.videojs(elIdentifier, {
          autoplay: settings.options.autoplay,
          loop: settings.options.loop,
          muted: settings.options.muted,
          controls: settings.options.controls,
          sources: setSrc(settings)
        }, function () {
          if (settings.options.autoplay) {
            player.autoplay(true)
          } else {
            player.bigPlayButton.show()
          }
        })
        // videoIdentifier.push(elIdentifier)
        players[elIdentifier] = player
        let el = player.el()
        if (settings.options.sound_icon) {
          $(el).append('<div class="audio-track"></div>')
          $(el).find('.audio-track').on('click', function () {
            let audioCheck = player.muted()
            resetPlayers(players)
            $(el).toggleClass('using-audio', audioCheck)
            player.muted(!audioCheck)
          })
        }
      }
    })
  }

  function resetPlayers (players) {
    for (let i in players) {
      let el = players[i].el()
      $(el).removeClass('using-audio')
      players[i].muted(true)
    }
  }

  function setSrc (settings) {
    let srcMobile = settings.srcMobile
    let srcDesktop = settings.srcDesktop
    let sources = settings.options.sources
    if (sources.length && srcMobile && srcDesktop) {
      for (let i in sources) {
        sources[i].src = (isPortrait() && srcMobile ? srcMobile : srcDesktop)
      }
    }
    return sources
  }
}
