<template>
  <div class="search-filter-recap" v-show="filters.length && !this.$store.state.ui.isMobile">
    <div class="filters-list">
      <b-nav class="list nav align-items-center">
        <li>
          <button class="btn btn-none clear-filter bcm-link bcm-link-primary bcm-link-noupper p-0" @click.prevent="removeFilter()">
            {{$t('Clear all filter')}}
          </button>
        </li>
        <li v-for="filter in filters" :key="filter.value">
          <button type="button" class="btn btn-none active-filter" @click.prevent="removeFilter(filter.value, filter.attrCode)">
            <span class="filter-label">{{filter.label}}</span>
            <i class="remove-filter aqz-icon aqz-icon-04-close"></i>
          </button>
        </li>
      </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    availableFilters: {
      type: Object,
      required: true,
      default: null
    },
    filtersActive: {
      type: Object,
      default: null
    }
  },
  computed: {
    filters () {
      let filters = []
      for (let i in this.filtersActive) {
        if (this.filtersActive[i]) {
          this.filtersActive[i].forEach(value => {
            if (Object.keys(this.filterParentMap).length > 0) {
              filters.push({
                'attrCode': i,
                label: this.filterParentMap[value].label,
                value: this.filterParentMap[value].value
              })
            }
          })
        }
      }
      return filters
    },
    filterParentMap (value) {
      let availableFilter = this.availableFilters
      let filterMap = {}
      for (let i in availableFilter) {
        if (availableFilter[i] && availableFilter[i].options) {
          availableFilter[i].options.forEach(opt => {
            filterMap[opt.value] = opt
          })
        }
      }
      return filterMap
    }
  },
  methods: {
    removeFilter (newValues, attrCode) {
      // find filter selected in filtersActive and remove
      if (newValues && this.filtersActive[attrCode] && this.filtersActive[attrCode].length) {
        this.filtersActive[attrCode].splice(this.filtersActive[attrCode].indexOf(newValues), 1)
        return this.$emit('changeFilter', this.filtersActive)
      } else {
        return this.$emit('changeFilter', null)
      }
    }
  }
}
</script>
