
export default {
  it: {
    // BLOG
    'blog': 'stories-it',
    // CORPORATE
    'contact-us': 'contattaci',
    'boutiques': 'negozi',
    'work-with-us': 'lavora-con-noi',
    // CUSTOMER CARE
    'return-refunds': 'resi-rimborsi',
    'time-of-delivery': 'tempi-di-spedizione',
    'shipping': 'spedizione',
    'payment': 'metodi-di-pagamento',
    'yatch-delivery-service': 'yatch-servizio-di-spedizione',
    'faq': 'faq',
    'information-covid-19': 'informazioni-covid-19',
    'track-order': 'traccia-il-mio-ordine',
    // Legal info
    'terms-and-conditions': 'area-legale',
    'conditions-of-sale': 'condizioni-di-vendita',
    'term-of-use': 'condizioni-di-uso',
    'privacy-policy': 'informativa-privacy',
    'cookies': 'cookie',
    'dispute-resolution': 'risoluzione-controversie',
    'after-care-sales': 'servizio-post-vendita',
    // 'seller-legal-warranty': 'garanzia-legale-del-venditore'
    'stories-it': 'stories-it',
    'contattaci': 'contattaci',
    'negozi': 'negozi',
    'lavora-con-noi': 'lavora-con-noi',
    'tempi-di-spedizione': 'tempi-di-spedizione',
    'spedizione': 'spedizione',
    'metodi-di-pagamento': 'metodi-di-pagamento',
    'informazioni-covid-19': 'informazioni-covid-19',
    'traccia-il-mio-ordine': 'traccia-il-mio-ordine',
    'servizio-post-vendita': 'servizio-post-vendita',
    // NEW REDIRECTS
    // AREA LEGALE
    'area-legale/resi-rimborsi': 'area-legale/resi-rimborsi',
    'area-legale/condizioni-di-vendita': 'area-legale/condizioni-di-vendita',
    'area-legale/condizioni-di-uso': 'area-legale/condizioni-di-uso',
    'area-legale/informativa-privacy': 'area-legale/informativa-privacy',
    'area-legale/cookie': 'area-legale/cookie',
    'area-legale/risoluzione-controversie': 'area-legale/risoluzione-controversie',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/negozi.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzura-world/about-aquazzura/la-storia.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzura-world/about-aquazzura/the-designer.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzura-world/about-aquazzura/craftsmanship.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-it/news-it.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-it/city-guide-it.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-it/events-it.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-it/interview-it.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT',
    // CUSTOMER CARE
    'customer-care/contattaci': 'customer-care/contattaci',
    'customer-care/spedizione': 'customer-care/spedizione',
    'customer-care/metodi-di-pagamento': 'customer-care/metodi-di-pagamento',
    'customer-care/servizio-post-vendita': 'customer-care/servizio-post-vendita',
    'product-care': 'product-care'
  },
  en: {
    // BLOG
    'blog': 'stories-en',
    // CORPORATE
    'work-with-us': 'work-with-us',
    // Legal info
    'terms-and-conditions': 'legal-info',
    // 'seller-legal-warranty': 'legal-guarantee-of-conformity'
    'stories-it': 'stories-en',
    'contattaci': 'contact-us',
    'negozi': 'boutiques',
    'lavora-con-noi': 'work-with-us',
    'tempi-di-spedizione': 'time-of-delivery',
    'spedizione': 'shipping',
    'metodi-di-pagamento': 'payment',
    'informazioni-covid-19': 'information-covid-19',
    'traccia-il-mio-ordine': 'track-order',
    'servizio-post-vendita': 'after-care-sales',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'legal-info/return-refunds',
    'area-legale/condizioni-di-vendita': 'legal-info/conditions-of-sale',
    'area-legale/condizioni-di-uso': 'legal-info/term-of-use',
    'area-legale/informativa-privacy': 'legal-info/privacy-policy',
    'area-legale/cookie': 'legal-info/cookies',
    'area-legale/risoluzione-controversie': 'legal-info/dispute-resolution',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/boutiques.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzura-world/about-aquazzura/about.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzura-world/about-aquazzura/the-designer.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzura-world/about-aquazzura/craftsmanship.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-en/news-en.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-en/news-en/fall-winter-2023-EN',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-en/city-guide-en.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-en/city-guide-en/edgardo-osorios-paris-city-guide-EN',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-en/events-en.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-en/events-en/event-paris-en',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-en/interviews-en.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-en/interviews-en/Poppy-interview-EN',
    // CUSTOMER CARE
    'customer-care/contattaci': 'customer-care/contact-us',
    'customer-care/spedizione': 'customer-care/shipping',
    'customer-care/metodi-di-pagamento': 'customer-care/payment',
    'customer-care/servizio-post-vendita': 'customer-care/after-care-sales',
    'product-care': 'product-care'
  },
  de: {
    // BOUTIQUES
    'boutiques': 'boutiquen',
    // BLOG
    'blog': 'stories-de',
    // CORPORATE
    'corporate': 'unternehmens',
    'exclusive-services': 'exklusive-services',
    'contact-us': 'kontaktiere-uns',
    // CUSTOMER CARE
    'customer-care': 'kundendienst',
    'conditions-of-sale': 'allgemeine-verkaufsbedingungen',
    'return-refunds': 'rucksendungen-und-erstattungen',
    'time-of-delivery': 'lieferzeit',
    'yatch-delivery-service': 'yacht-lieferservice',
    'payments': 'bezahlungen',
    'faq': 'faq-de',
    'shipping': 'liefertermine',
    'payment': 'zahlungsbedingungen',
    'information-covid-19': 'covid-19-informationen',
    'track-order': 'verfolgen-sie-ihre-bestellung',
    // Legal info
    'terms-and-conditions': 'rechtliche-informationen',
    'term-of-use': 'tallgemeine-benutzungsbedingungen',
    'privacy-policy': 'datenschutzrichtlinie',
    'cookies': 'cookies-de',
    'dispute-resolution': 'losung-von-streitfragen',
    // 'seller-legal-warranty': 'gesetzliche-konformitatsgarantie',
    'after-care-sales': 'kundeservice',
    'stories-it': 'stories-de',
    'contattaci': 'kontaktiere-uns',
    'negozi': 'boutiquen',
    'tempi-di-spedizione': 'lieferzeit',
    'spedizione': 'liefertermine',
    'metodi-di-pagamento': 'zahlungsbedingungen',
    'informazioni-covid-19': 'covid-19-informationen',
    'traccia-il-mio-ordine': 'verfolgen-sie-ihre-bestellung',
    'servizio-post-vendita': 'kundeservice',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'rechtliche-informationen/rucksendungen-und-erstattungen',
    'area-legale/condizioni-di-vendita': 'rechtliche-informationen/allgemeine-verkaufsbedingungen',
    'area-legale/condizioni-di-uso': 'rechtliche-informationen/tallgemeine-benutzungsbedingungen',
    'area-legale/informativa-privacy': 'rechtliche-informationen/datenschutzrichtlinie',
    'area-legale/cookie': 'rechtliche-informationen/cookies-de',
    'area-legale/risoluzione-controversie': 'rechtliche-informationen/losung-von-streitfragen',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/boutiquen.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzura-world/about-aquazzura/die-geschichte.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzura-world/about-aquazzura/der-designer.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzura-world/about-aquazzura/handwerk.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-de/news-de.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-de/news-de/fall-winter-2023-collection-DE',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-de/city-guide-de.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-de/city-guide-de/edgardo-osorios-paris-city-guide-DE',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-de/events-de.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-de/events-de/aquazzura-celebrate-bag-launch-in-paris-de',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-de/interviews-de.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-de/interviews-de/Poppy-Delevingne-DE',
    // CUSTOMER CARE
    'customer-care/contattaci': 'kundendienst/kontaktiere-uns',
    'customer-care/spedizione': 'kundendienst/liefertermine',
    'customer-care/metodi-di-pagamento': 'kundendienst/zahlungsbedingungen',
    'customer-care/servizio-post-vendita': 'kundendienst/kundeservice',
    'product-care': 'product-care'
  },
  ja: {
    // CORPORATE
    'corporate': 'kaisha-gaiyo',
    'exclusive-services': 'tokubetsu-sabisu',
    'boutiques': 'shoppu-kensaku',
    'contact-us': 'o-toiawase-saki',
    // CUSTOMER CARE
    'customer-care': 'kasutamakea',
    'return-refunds': 'henpin-henkin',
    'time-of-delivery': 'haitatsu-jikan',
    'yatch-delivery-service': 'yotto-haitatsu-sabisu',
    'payments': 'shiharai-hoho',
    'faq': 'yokuarushitsumon',
    'information-covid-19': 'info-covid-19',
    'track-order': 'torakkuoda',
    // Legal info
    'terms-and-conditions': 'horitsu-joho',
    'conditions-of-sale': 'hanbai-no-joken',
    'term-of-use': 'riyo-kiyaku',
    'privacy-policy': 'puraibetoporishi',
    'cookies': 'kukki',
    'dispute-resolution': 'funso-kaiketsu',
    'seller-legal-warranty': 'tekigo-sei-no-hoteki-hoshp',
    'contattaci': 'o-toiawase-saki',
    'negozi': 'shoppu-kensaku',
    'tempi-di-spedizione': 'haitatsu-jikan',
    'informazioni-covid-19': 'info-covid-19',
    'traccia-il-mio-ordine': 'torakkuoda',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'horitsu-joho/henpin-henkin',
    'area-legale/condizioni-di-vendita': 'horitsu-joho/hanbai-no-joken',
    'area-legale/condizioni-di-uso': 'horitsu-joho/riyo-kiyaku',
    'area-legale/informativa-privacy': 'horitsu-joho/puraibetoporishi',
    'area-legale/cookie': 'horitsu-joho/kukki',
    'area-legale/risoluzione-controversie': 'horitsu-joho/funso-kaiketsu',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/shoppu-kensaku.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-ja/news-ja.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-ja/news-ja/fall-winter-2023-collection-JA',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-ja/city-guide-ja.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-ja/city-guide-ja/edgardo-osorios-paris-city-guide-JA',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-ja/events-ja.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-ja/events-ja/event-paris-ja',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-ja/interviews-ja.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-ja/interviews-ja/Poppy-interview-JA',
    // CUSTOMER CARE
    'customer-care/contattaci': 'kasutamakea/o-toiawase-saki',
    'product-care': 'product-care'
  },
  es: {
    // BLOG
    'blog': 'stories-es',
    // CUSTOMER CARE
    'contact-us': 'contactenos',
    'customer-care': 'servitio-al-cliente',
    'faq': 'faq-es',
    'shipping': 'tiempos-de-anvio',
    'payment': 'metodos-de-pago',
    'after-care-sales': 'servicio-postventa',
    // Legal info
    'terms-and-conditions': 'informacion-legal',
    'conditions-of-sale': 'condiciones-de-vendita',
    'term-of-use': 'condiciones-generales-de-uso',
    'privacy-policy': 'privacy-policy-es',
    'cookies': 'cookies-es',
    'return-refunds': 'devoluciones-y-reembolsos',
    'dispute-resolution': 'resoluction-de-controversias',
    'stories-it': 'stories-es',
    'contattaci': 'contactenos',
    'spedizione': 'tiempos-de-anvio',
    'metodi-di-pagamento': 'metodos-de-pago',
    'servizio-post-vendita': 'servicio-postventa',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'informacion-legal/devoluciones-y-reembolsos',
    'area-legale/condizioni-di-vendita': 'informacion-legal/condiciones-de-vendita',
    'area-legale/condizioni-di-uso': 'informacion-legal/condiciones-generales-de-uso',
    'area-legale/informativa-privacy': 'informacion-legal/privacy-policy-es',
    'area-legale/cookie': 'informacion-legal/cookies-es',
    'area-legale/risoluzione-controversie': 'informacion-legal/resoluction-de-controversias',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/boutiques.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzura-world/about-aquazzura/historia.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzura-world/about-aquazzura/the-designer.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzura-world/about-aquazzura/craftsmanship.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-es/news-es.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-es/news-es/fall-winter-2023-collection-ES',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-es/city-guide-es.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-es/city-guide-es/edgardo-osorios-paris-city-guide-ES',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-es/events-es.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-es/events-es/aquazzura-celebrate-bag-launch-in-paris-es',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-es/interviews-es.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-es/interviews-es/Poppy-Delavingne-ES',
    // CUSTOMER CARE
    'customer-care/contattaci': 'servitio-al-cliente/contactenos',
    'customer-care/spedizione': 'servitio-al-cliente/tiempos-de-anvio',
    'customer-care/metodi-di-pagamento': 'servitio-al-cliente/metodos-de-pago-es',
    'customer-care/servizio-post-vendita': 'servitio-al-cliente/servicio-postventa',
    'product-care': 'product-care'
  },
  fr: {
    // BLOG
    'blog': 'stories-fr',
    // CUSTOMER CARE
    'customer-care': 'service-a-la-clientele',
    'contact-us': 'contactez-nous',
    'faq': 'faq-fr',
    'shipping': 'delais-de-livraison',
    'payment': 'methodes-de-paiement',
    'after-care-sales': 'service-de-suivi',
    // Legal info
    'terms-and-conditions': 'informations-legales',
    'conditions-of-sale': 'conditions-generales-de-vente',
    'term-of-use': 'conditions-generales-de-utilisation',
    'privacy-policy': 'politique-de-confidentialite',
    'cookies': 'cookies-fr',
    'return-refunds': 'retours-et-remboursements',
    'dispute-resolution': 'reglement-des-differends',
    'stories-it': 'stories-fr',
    'contattaci': 'contactez-nous',
    'spedizione': 'delais-de-livraison',
    'metodi-di-pagamento': 'methodes-de-paiement',
    'servizio-post-vendita': 'service-de-suivi',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'informations-legales/retours-et-remboursements',
    'area-legale/condizioni-di-vendita': 'informations-legales/conditions-generales-de-vente',
    'area-legale/condizioni-di-uso': 'informations-legales/conditions-generales-de-utilisation',
    'area-legale/informativa-privacy': 'informations-legales/politique-de-confidentialite',
    'area-legale/cookie': 'informations-legales/cookies-fr',
    'area-legale/risoluzione-controversie': 'informations-legales/reglement-des-differends',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzura-world/about-aquazzura/boutiques.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzura-world/about-aquazzura/l-histoire.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzura-world/about-aquazzura/le-createur.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzura-world/about-aquazzura/artisanat.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzura-world/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzura-world/stories-fr/news-fr.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzura-world/stories-fr/news-fr/fall-winter-2023-collection-FR',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzura-world/stories-fr/city-guide-fr.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzura-world/stories-fr/city-guide-fr/edgardo-osorios-paris-city-guide-fr',
    'aquazzura-world/stories-it/events-it.html': 'aquazzura-world/stories-fr/events-fr.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzura-world/stories-fr/events-fr/aquazzura-celebra-la-presentacion-de-la-coleccion-de-bolsos-en-paris-fr',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzura-world/stories-fr/interviews-fr.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzura-world/stories-fr/interviews-fr/Poppy-Delevingne-FR',
    // CUSTOMER CARE
    'customer-care/contattaci': 'service-a-la-clientele/contactez-nous',
    'customer-care/spedizione': 'service-a-la-clientele/delais-de-livraison',
    'customer-care/metodi-di-pagamento': 'service-a-la-clientele/methodes-de-paiement',
    'customer-care/servizio-post-vendita': 'service-a-la-clientele/service-de-suivi',
    'product-care': 'product-care'
  },
  zh: {
    // BOUTIQUES
    'aquazzura-world': 'aquazzuradeshijie',
    'boutiques': 'zhuanmaidian',
    // BLOG
    'blog': 'stories-cn',
    // CUSTOMER CARE
    'customer-care': 'kehu-guanhuai',
    'contact-us': 'lianxi-kefu',
    'faq': 'changjian-wenti',
    'shipping': 'yunshu-shijian',
    'payment': 'fukuan-fangshi',
    'after-care-sales': 'shouhou-fuwu',
    // Legal info
    'terms-and-conditions': 'falu-xinxi',
    'conditions-of-sale': 'changgui-xiaoshou-tiaokuan',
    'term-of-use': 'changgui-shiyong-tiaokuan',
    'privacy-policy': 'yinsi-zhengce',
    'cookies': 'guanyu-cookies-de-xinxi',
    'return-refunds': 'guanyu-chehui-de-xinxi-quanli',
    'dispute-resolution': 'zhengyi-de-jiejue-fangshi',
    'stories-it': 'stories-cn',
    'contattaci': 'lianxi-kefu',
    'negozi': 'zhuanmaidian',
    'spedizione': 'yunshu-shijian',
    'metodi-di-pagamento': 'fukuan-fangshi',
    'servizio-post-vendita': 'shouhou-fuwu',
    // NEW REDIRECTS
    'area-legale/resi-rimborsi': 'falu-xinxi/guanyu-chehui-de-xinxi-quanli',
    'area-legale/condizioni-di-vendita': 'area-legale/changgui-xiaoshou-tiaokuan',
    'area-legale/condizioni-di-uso': 'area-legale/changgui-shiyong-tiaokuan',
    'area-legale/informativa-privacy': 'area-legale/yinsi-zhengce',
    'area-legale/cookie': 'area-legale/guanyu-cookies-de-xinxi',
    'area-legale/risoluzione-controversie': 'area-legale/zhengyi-de-jiejue-fangshi',
    // AQUAZZURA WORLD
    'aquazzura-world/about-aquazzura/negozi.html': 'aquazzuradeshijie/about-aquazzura/zhuanmaidian.html',
    'aquazzura-world/about-aquazzura/la-storia.html': 'aquazzuradeshijie/about-aquazzura/lishi.html',
    'aquazzura-world/about-aquazzura/the-designer.html': 'aquazzuradeshijie/about-aquazzura/shejishi.html',
    'aquazzura-world/about-aquazzura/craftsmanship.html': 'aquazzuradeshijie/about-aquazzura/gongyi.html',
    'aquazzura-world/about-aquazzura/aquazzura.html': 'aquazzuradeshijie/about-aquazzura/aquazzura.html',
    // STORIES
    'aquazzura-world/stories-it/news-it.html': 'aquazzuradeshijie/stories-cn/news-cn.html',
    'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT': 'aquazzuradeshijie/stories-cn/news-cn/fall-winter-2023-collection-ZH',
    'aquazzura-world/stories-it/city-guide-it.html': 'aquazzuradeshijie/stories-cn/city-guide-cn.html',
    'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT': 'aquazzuradeshijie/stories-cn/city-guide-cn/edgardo-osorios-paris-city-guide-ZH',
    'aquazzura-world/stories-it/events-it.html': 'aquazzuradeshijie/stories-cn/events-cn.html',
    'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it': 'aquazzuradeshijie/stories-cn/events-cn/aquazzura-festeggia-la-collezione-di-borse-a-parigi-zh',
    'aquazzura-world/stories-it/interview-it.html': 'aquazzuradeshijie/stories-cn/interviews-cn.html',
    'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT': 'aquazzuradeshijie/stories-cn/interviews-cn/Poppy-Delevingne-ZH',
    // CUSTOMER CARE
    'customer-care/contattaci': 'kehu-guanhuai/lianxi-kefu',
    'customer-care/spedizione': 'kehu-guanhuai/yunshu-shijian',
    'customer-care/metodi-di-pagamento': 'kehu-guanhuai/fukuan-fangshi',
    'customer-care/servizio-post-vendita': 'kehu-guanhuai/shouhou-fuwu',
    'product-care': 'product-care'
  }
}
