<template>
  <div class="text-left grid-and-collapse">
    <button v-b-toggle="`filters_${attrCode}`" class="btn btn-none full-width button-collapse collapsed">
      {{ label }} <span v-show="optionChosen.length" class="ml-1">({{optionChosen.length}})</span>
      <span class="grid-and-collapse_icon arrow-down-end"></span>
    </button>
    <b-collapse :id="`filters_${attrCode}`" class="collapse-item">
      <component
        :is="typeFilter"
        :attrCode="attrCode"
        :optionChosen="optionChosen"
        :options="options"
        @changeFilter="$emit('changeFilter', $event)"/>
    </b-collapse>
  </div>
</template>

<script>
import CheckboxFilter from './FiltersLayout/CheckboxFilter'

export default {
  props: {
    attrCode: {
      type: String,
      required: true
    },
    options: {
      type: Object | Array,
      required: false
    },
    optionChosen: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    typeFilter () {
      return 'checkbox-filter'
    }
  },
  components: {
    CheckboxFilter
  }
}
</script>
