/**
 * WishList Mutations
 */

export const saveWishlistItem = `
  mutation SaveWishlistItem($wishlistItem: WishlistItemInput!) {
    saveWishlistItem(wishlistItem: $wishlistItem) {
        id
        sku
        qty
        description
        added_at
    }
  }`

export const addGifCardToWishlist = `
  mutation AwGcAddGiftCardToWishList($awGcGiftCardOptionsInput: awGcGiftCardOptionsInput!) {
    awGcAddGiftCardToWishList(aw_giftcard_options: $awGcGiftCardOptionsInput)
  }`

export const removeWishlistItem = ` 
mutation RemoveProductFromWishlist($itemId: ID!) {
  removeProductFromWishlist(itemId: $itemId)
}
`

export const moveAllToCart = ` 
mutation MoveWishlistToCart {
  moveWishlistToCart
}
`
