<template>
    <div class="filters-action">
      <div class="d-flex align-items-center">
        <button class="btn btn-secondary filters-action__button" :disabled="disabled" @click.prevent="$emit('clear')" v-show="showClear">{{$t('clear')}}</button>
        <button class="btn btn-primary filters-action__button" :disabled="disabled" @click.prevent="$emit('apply')">{{$t('apply')}}</button>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    showClear: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    clearFilterKey: {
      type: String,
      default: 'clear-filter'
    }
  }
}
</script>
