import Vue from 'vue'
import SocialShare from '@/theme/components/SocialShare/SocialShare'

export function init () {
  if (process.client) {
    let $ = global.$
    $('body').on('widget:sharepage', (event) => {
      // alert('to do share button')
      let $shareContainer = $(event.target.parentElement)
      let $shareCollapse = $('.widget-share-btn-collapse', $shareContainer)
      // if empty inject
      if ($shareCollapse.children().length === 0) {
        let ComponentClass = Vue.extend(SocialShare)
        let instance = new ComponentClass({
          i18n: global.$i18n,
          propsData: {
            url: location.href,
            title: document.title,
            description: document.title
          }
        })
        instance.$mount()
        $shareCollapse.html(instance.$el)
      }
      $shareCollapse.slideToggle()
    })
  }
}
