import '@babel/polyfill'
import '@/scss/style.scss'
import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import filters from './filters'
import './directives'
import { tokenOrRedirect } from './middlewares/auth.middleware'
import { init as storeInit } from './middlewares/store.middleware'
import { init as redirectInit } from './middlewares/redirect.middleware'

Vue.config.productionTip = true

if (process.env.NODE_ENV === 'production') {
  Vue.config.silent = true
  Vue.config.productionTip = false
}

export default ({ router, store }) => {
  global.$router = router
  // load filters
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
  return new Vue({
    router,
    store,
    i18n: i18n(store),
    middlewares: [
      redirectInit,
      storeInit,
      // authCookie,
      tokenOrRedirect
    ],
    render: h => h(App)
  })
}
