<template>
  <div class="wrapp-product-media__image-3d">
    <iframe
    title="ACQUAZZURA AUGMENTED REALITY"
    frameborder="0" allowfullscreen mozallowfullscreen="true"
    webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking"
    xr-spatial-tracking execution-while-out-of-viewport
    execution-while-not-rendered web-share
      :src=getUrl3D
      class="iframe-media-3D"
      style="width: 100%; height: 100%;">
    </iframe>
    <a v-if="!isMobile" @click="openModalQrCode">
      <img class="icon-augmented-reality" :src="Icon.url" :alt="Icon.alt">
      <span class="text-augmented-reality">AUGMENTED REALITY</span>
      <modal-qr-code
        :modal-show="showModalQrCode"
        :urlQrCode="getUrlAugmentedReality"
      />
    </a>
  </div>
</template>
<script>

import { isMobile } from '@/helpers/utils'
import ModalQrCode from '@/components/Modal/ModalQrCode.vue'
import AugmentedRealityIcon from '@/theme/assets/image/augmented_reality_icon.svg'
import Config from '@/config'

export default {
  props: {
    Img3DCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      Icon: {
        url: AugmentedRealityIcon,
        alt: 'Augmented Reality Icon'
      },
      isMobile: false,
      showModalQrCode: false
    }
  },
  computed: {
    getUrl3D () {
      return Config.Theme.AugmentedReality.media3DUrl.replace('{{modelNumber}}', this.Img3DCode)
    },
    getUrlAugmentedReality () {
      return Config.Theme.AugmentedReality.AugmentedRealtyUrl.replace('{{modelNumber}}', this.Img3DCode)
    }
  },
  mounted () {
    if (process.client) {
      this.isMobile = isMobile()
    }
  },
  methods: {
    openModalQrCode () {
      this.$store.commit('ui/showModalQrCode', true)
    }
  },
  components: {
    ModalQrCode
  }
}
</script>
<style>
  .wrapp-product-media__image-3d {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
  }

  .icon-augmented-reality {
    position: absolute;
    bottom: 10px;
    left: 10px;
    cursor: pointer;
  }

  .text-augmented-reality {
    position: absolute;
    bottom: 10px;
    left: 35px;
    cursor: pointer;
  }
  .text-augmented-reality{
    position: absolute;
    bottom: 10px;
    left: 35px;
    cursor: pointer;

    transition: 300ms;
    border-bottom: 1px solid transparent;
}
  .text-augmented-reality:hover {
    border-color: black;
  }

  .iframe-media-3D{
    position: relative;
    height: auto;
    width: 100%;
  }
</style>
