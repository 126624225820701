import _ from 'lodash'
import { initDomClasses } from '../windowGuard/resize'

export function init () {
  global.$('body').on('widget:imagehandler', (event, placeholder) => {
    if (placeholder.isHero) {
      initDomClasses()
    }
    if (global.$(placeholder.ihIdentifier).length > 0) {
      const imagehandlerInstance = placeholder.ihIdentifier
      let ImageHandlerOptions = global.$(imagehandlerInstance).data()
      _.forIn(ImageHandlerOptions, function (value, key) {
        key = _.camelCase(key)
      })
      let ih = new global.ImageHandler(imagehandlerInstance, ImageHandlerOptions)
      ih.init()
    }
  })
}
