<template>
   <div class="wrapp-select-language" v-if="currentCountry">
     <div class="shipping-selector">
       <p class="t-big">{{ $t('shipping-to') }}:</p>
       <div class="wrapp-ctas">
         <div class="current-shipping-country">
            <span class="bcm-link bcm-link-primary">{{ currentCountry.country_name }}</span>
         </div>
         <div class="cta-ch-country">
           <button class="btn btn-none bcm-link bcm-link-secondary" @click.prevent="openChCountry">{{ $t('chooseCountry.ch-country-region') }}</button>
         </div>
       </div>
     </div>
     <div class="language-selector">
       <p class="t-big">{{ $t('language') }}:</p>
       <div class="wrapp-language">
         <ul class="nav flex-column">
           <li v-for="locale in currentCountry.locales" :key="locale.store_view">
             <a :href="redirectLocale(locale.store_view)" class="ch-country-link state-link bcm-link bcm-link-secondary" :class="{active : locale.store_view === currentStoreView}" v-t="`site-country.${locale.language}`" @click="setLocations(locale.store_view)"></a>
           </li>
         </ul>
       </div>
     </div>
   </div>
</template>

<script>
import { changeLanguageFromCurrentUrl } from '@/helpers'

export default {
  computed: {
    currentCountry () {
      return this.$store.state.ui.currentCountry
    },
    currentStoreView () {
      return this.$store.state.ui.storeViewCode
    }
  },
  methods: {
    openChCountry () {
      return this.$store.commit('ui/setShowCountriesModal', true)
    },
    redirectLocale (newLocale) {
      if (process.client) {
        return changeLanguageFromCurrentUrl(this.$route.fullPath, newLocale, this.$router)
      }
      return null
    },
    setLocations (storeView) {
      global.$cookies.set('country-choice', storeView, '30d')
    }
  }
}
</script>
