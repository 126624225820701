export default `{
  blogCategories {
    items {
      category_id
      parent_category_id
      filtered_content_ext
      identifier
      include_in_menu
      is_active
      path
      position
      title
    }
  }
}`
