export default {
  messages: {
    required: 'required field',
    requireTrue: 'required field',
    phoneNumber: 'invalid telephone',
    alpha: 'only contain alphabetic characters',
    email: 'The email value is not valid',
    min: (fieldName, params) => `minimum number of characters ${params[0]}`,
    confirmed: 'The password confirmation does not match.',
    numeric: 'just numeric value',
    length: 'incorrect length'
  }
}
