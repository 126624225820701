export const CmsSlugs = [
  // AREA LEGALE
  'area-legale/resi-rimborsi',
  'area-legale/condizioni-di-vendita',
  'area-legale/condizioni-di-uso',
  'area-legale/informativa-privacy',
  'area-legale/cookie',
  'area-legale/risoluzione-controversie',
  // AQUAZZURA WORLD
  'aquazzura-world/about-aquazzura/la-storia.html',
  'aquazzura-world/about-aquazzura/the-designer.html',
  'aquazzura-world/about-aquazzura/craftsmanship.html',
  'aquazzura-world/about-aquazzura/negozi.html',
  'aquazzura-world/about-aquazzura/aquazzura.html',
  // STORIES
  'aquazzura-world/stories-it/news-it.html',
  'aquazzura-world/stories-it/news-it/fall-winter-2023-collection-IT',
  'aquazzura-world/stories-it/city-guide-it.html',
  'aquazzura-world/stories-it/city-guide-it/edgardo-osorios-paris-city-guide-IT',
  'aquazzura-world/stories-it/events-it.html',
  'aquazzura-world/stories-it/events-it/aquazzura-celebre-a-paris-sa-collection-de-sacs-it',
  'aquazzura-world/stories-it/interview-it.html',
  'aquazzura-world/stories-it/interview-it/Poppy-Delavingne-IT',
  // CUSTOMER CARE
  'contattaci',
  'spedizione',
  'metodi-di-pagamento',
  'servizio-post-vendita',
  'product-care'
]
