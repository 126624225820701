export default {
  paymentMethods: '/rest/{{storeViewCode}}/V1/carts/mine/retrieve-adyen-payment-methods',
  paymentMethodsGuest: '/rest/{{storeViewCode}}/V1/guest-carts/{{cartId}}/retrieve-adyen-payment-methods',
  placeOrder: '/rest/{{storeViewCode}}/V1/carts/mine/payment-information',
  placeOrderGuest: '/rest/{{storeViewCode}}/V1/guest-carts/{{cartId}}/payment-information',
  paymentStatus: '/rest/{{storeViewCode}}/V1/adyen/orders/payment-status',
  paymentDetails: '/rest/{{storeViewCode}}/V1/adyen/paymentDetails',
  magentoMap: {
    'amex': 'AE',
    'discover': 'DI',
    'mc': 'MC',
    'visa': 'VI'
  },
  paymentMethodsUnsupported: [
    'scheme',
    'boleto',
    'wechatpayQR',
    'ratepay'
  ],
  /**
   * apple pay config
   */
  applePayVersion: 6
}
