export default {
  messages: {
    required: 'campo richiesto',
    requireTrue: 'campo richiesto',
    phoneNumber: 'numero di telefono non valido',
    alpha: 'inserire solo caratteri alfabetici',
    email: 'email non valida',
    min: (fieldName, params) => `la ${fieldName} deve avere almeno ${params[0]} caratteri`,
    confirmed: (fieldName) => `La ${fieldName} non corrisponde a quella inserita`,
    numeric: 'solo valori numerici',
    length: 'la lunghezza non è corretta'
  }
}
