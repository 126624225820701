const getPickupLocationIdFromCode = `
query getPickupIdFromLocation($input: getPickupIdFromLocationInput) {
  getPickupIdFromLocation(input: $input) 
}
`

const setPickupIdOnCart = `
mutation setPickupIdOnCart($input: SetPickupIdOnCartInput) {
  setPickupIdOnCart(input: $input) {
    cart {
      id
    }
  }
}`

const getStorePickupByCountry = `
query getStorePickupByCountry($ids: String) {
  getStorePickupByCountry(ids: $ids) {
    code
    address
    city
    country
    description
    email
    is_boutique
    latitude
    longitude
    name
    phone
    region
    tags
    working_hours
  }
}
`

const getStoreLocatorConfig = `query {
  getMWLocationConfig{
    defaultCity
    defaultScale
    markerIcon
  }
}`

export {
  setPickupIdOnCart,
  getStorePickupByCountry,
  getPickupLocationIdFromCode,
  getStoreLocatorConfig
}
