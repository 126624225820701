import { initDomClasses } from '../windowGuard/resize'
import $ from 'jquery'
import _ from 'lodash'

export function init () {
  if (process.client) {
    $('body').on('widget:swiper', (event, placeholder) => {
      if (placeholder.isHero) {
        initDomClasses()
      }
      let $ref = $(placeholder.swIdentifier)
      if ($ref.length > 0 && $ref.data('vue-init') === undefined) {
        $ref.data('vue-init', true)
        const swIdentifier = placeholder.swIdentifier
        const useSettings = placeholder.useSettings
        let settings = {}
        // find settings from data-attributes
        // otherwise get from magento (trigger)
        if (!useSettings) {
          settings = $(swIdentifier).data()
          _.forIn(settings, function (value, key) {
            key = _.camelCase(key)
          })
        } else {
          settings = { ...placeholder.swSettings }
        }
        // init swiper
        let sw = new global.Swiper(swIdentifier, settings)
        // add logic
        sw.on('init', function (s) {
          checkForMenuColor(placeholder)
          setIndex(placeholder, sw)
        })
        sw.on('slideChange', function (s) {
          // loop on end go back to first slide (not duplicate)
          let isLastSlide = (sw.slides.length === (sw.activeIndex + 1))
          let $activeSlide = $(swIdentifier + ' .swiper-slide.swiper-slide-active').first()
          if (isLastSlide && settings.loop && $activeSlide.find('video').length) {
            sw.slideToLoop(0)
          }
          setIndex(placeholder, sw)
          // setAnimationOnSlideChange()
        })
        sw.on('transitionEnd', function (s) {
          checkForMenuColor(placeholder)
          // initWowOnTransitionEnd()
        })
        /* sw.on('slideNextTransitionStart', function (s) {
          // Same as "slideChangeTransitionStart" but for "forward" direction only;
          setAnimationOnSlideNextTransitionStart()
        })
        sw.on('slidePrevTransitionStart', function (s) {
          // Same as "slideChangeTransitionStart" but for "forward" direction only;
          setAnimationOnSlidePrevTransitionStart()
        }) */
        sw.init()
        $ref.closest('.bcm-simple-slide').addClass('show-slide')
        $ref.closest('.bc-sl-mg-category-slide').addClass('show-slide')
      }
    })
  }

  function checkForMenuColor (placeholder) {
    // if slide is in hero mode
    // check if slide inside has tag to color the menu
    let $activeSlide = $(placeholder.swIdentifier + ' .swiper-slide.swiper-slide-active').first()
    let menuColor = $activeSlide.data('menu-color')
    if (placeholder.isHero) {
      if (menuColor) {
        $('.whole-navigation .main-navigation').attr('use-style-color', true).css({ 'color': menuColor, 'fill': menuColor })
      } else {
        $('.whole-navigation .main-navigation').removeAttr('use-style-color').removeAttr('style')
      }
    }
  }

  function setIndex (placeholder, sw) {
    const realIndexIdentifier = placeholder.swIdentifier + ' .real-index'
    const slidesLenghtIdentifier = placeholder.swIdentifier + ' .slides-length'
    const $activeIndexElement = $(realIndexIdentifier)[sw.activeIndex]
    const $slidesLenghtElement = $(slidesLenghtIdentifier)
    let slidesLenght = sw.slides.length
    let activeIndex = sw.activeIndex
    if (sw.params.loop) {
      switch (sw.activeIndex) {
        case 0:
          activeIndex = slidesLenght - 3
          break
        case slidesLenght - 1:
          activeIndex = 0
          break
        default:
          --activeIndex
      }
      slidesLenght = slidesLenght - 2
    }
    if ($activeIndexElement && $slidesLenghtElement) {
      $($activeIndexElement).text(activeIndex + 1)
      $slidesLenghtElement.text(slidesLenght)
    }
  }
  // eslint-disable-next-line
  function setAnimationOnSlideNextTransitionStart () {
    const $slideNext = $('.swiper-slide-prev')
    const $slideActive = $('.swiper-slide-active')
    const $slideNextElements = $slideNext.find('.wow')
    const $slideActiveElements = $slideActive.find('.wow')
    $slideActiveElements.fadeIn('slow')
    $slideNextElements.fadeOut('slow')
  }
  // eslint-disable-next-line
  function setAnimationOnSlidePrevTransitionStart () {
    const $slidePrev = $('.swiper-slide-next')
    const $slideActive = $('.swiper-slide-active')
    const $slideNextElements = $slidePrev.find('.wow')
    const $slideActiveElements = $slideActive.find('.wow')
    $slideNextElements.fadeOut('slow')
    $slideActiveElements.fadeIn()
  }
}
