export const customerAddress = `
  id
  default_shipping
  default_billing
  city
  country_code
  firstname
  lastname
  postcode
  region{
    region_code
    region
    region_id       
  }
  region_id
  street
  telephone
`
export const customerOut = `  
  email
  default_shipping
  default_billing
  firstname
  lastname
  date_of_birth
  is_subscribed
  prefix
  addresses{
    ${customerAddress}
  }
  wishlist {
    id
    items_count
    items {
      id
      sku
      qty
      is_in_stock
      selectedOptions {
        label
        option_id
        option_value
        value
        attribute_code
        color_image
      }
      aw_giftcard_options {
        aw_gc_amount
        aw_gc_custom_amount
        aw_gc_template
        aw_gc_recipient_name
        aw_gc_recipient_email
        aw_gc_sender_name
        aw_gc_sender_email
        aw_gc_headline
        aw_gc_message
        aw_gc_delivery_date
        aw_gc_delivery_date_timezone
      }
      product {
        id
        name
        image {
          url
          label
        }
        sku
        stock_status
        special_price
        ...on AwGiftCardProduct {
          aw_gc_amounts
          aw_gc_custom_message_fields
          aw_gc_days_order_delivery
          aw_gc_description
          aw_gc_email_templates {
            image_url
            name
            value
          }
          aw_gc_expire
          aw_gc_open_amount_max
          aw_gc_open_amount_min
          aw_gc_type
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value,
              currency
          }
          quantity
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          },
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            attribute_id
            values {
              value_index
              label
              default_label
              store_label
              use_default_value
            }
          }
        }
      }
    }
  }
`
export default `query GetCustomer {
  customer{
    id
    group_id
    ${customerOut}
  }
}`
