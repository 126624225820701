/* eslint-disable */
export function getInnerHTMLServiceForce(lang) {
  return `
  var initESW = function(gslbBaseURL) {

    embedded_svc.settings.displayHelpButton = true; //Or false
    embedded_svc.settings.language = '${lang}';

    let nomevar, cognomevar, emailvar;
      nomevar = cognomevar = emailvar = ''
      let customer = $store.getters['user/getCustomer']
      if (Object.keys(customer).length > 0) {
        ({ nomevar, cognomevar, emailvar } = { nomevar: customer.firstname, cognomevar: customer.lastname, emailvar: customer.email })
      }

    embedded_svc.settings.defaultMinimizedText = 'Live Chat'; //(Defaults to Chat with an Expert)

    embedded_svc.settings.extraPrechatFormDetails = [{
        "label": "RecordTypeId",
        "value": "0127Q000000hEwPQAU", // record type del person account
        "displayToAgent": true
    }];


    embedded_svc.settings.extraPrechatInfo = [
        {
            entityName: "Contact",
            linkToEntityField: "ContactId",
            linkToEntityName: "Case",
            saveToTranscript: "ContactId",
            entityFieldMaps: [
                {
                    doCreate: false,
                    doFind: false,
                    fieldName: "LastName",
                    isExactMatch: false,
                    label: "Last Name",
                },
                {
                    doCreate: false,
                    doFind: false,
                    fieldName: "FirstName",
                    isExactMatch: false,
                    label: "First Name",
                },
                ,
                {
                    doCreate: false,
                    doFind: true,
                    fieldName: "Email",
                    isExactMatch: true,
                    label: "Email",
                },
            ],
        },
        {

            entityName: "Account",
            showOnCreate: true,
            linkToEntityField: "AccountId",
            linkToEntityName: "Case",
            saveToTranscript: "AccountId",
            entityFieldMaps: [
                {
                    doCreate: false,
                    doFind: false,
                    fieldName: "FirstName",
                    isExactMatch: false,
                    label: "First Name",
                },
                {
                    doCreate: false,
                    doFind: false,
                    fieldName: "LastName",
                    isExactMatch: false,
                    label: "Last Name",
                },
                {
                    doCreate: false,
                    doFind: false,
                    fieldName: "RecordTypeId",
                    isExactMatch: false,
                    label: "RecordTypeId",
                },
                {
                    doCreate: false,
                    doFind: true,
                    fieldName: "PersonEmail",
                    isExactMatch: true,
                    label: "Email",
                },
            ],
        },
        {
            entityName: "Case",
            showOnCreate: true,
            saveToTranscript: "Case",
            entityFieldMaps: [
                {
                    isExactMatch: false,
                    fieldName: "SuppliedEmail",
                    doCreate: true,
                    doFind: false,
                    label: "Email"
                },
                {
                    isExactMatch: false,
                    fieldName: "Lastname__c",
                    doCreate: true,
                    doFind: false,
                    label: "Last Name"
                },
                {
                    isExactMatch: false,
                    fieldName: "FirstName__c",
                    doCreate: true,
                    doFind: false,
                    label: "First Name"
                }
            ]
        },
    ];
    embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: nomevar,
        LastName: cognomevar,
        Email: emailvar,
      };

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.init(
        "https://aquazzura.my.salesforce.com",
        "https://aquazzura.my.salesforce-sites.com/unsubscribe",
        gslbBaseURL,
        "00D7Q000002nxJr",
        "Chat_Aquazzura", {
            baseLiveAgentContentURL: "https://c.la2-c1-cdg.salesforceliveagent.com/content",
            deploymentId: "5727Q0000010xSb",
            buttonId: "5737Q0000010xT0",
            baseLiveAgentURL: "https://d.la2-c1-cdg.salesforceliveagent.com/chat",
            eswLiveAgentDevName: "Chat_Aquazzura",
            isOfflineSupportEnabled: false,
        }
    );
};


if (!window.embedded_svc) {
    var s = document.createElement("script");
    s.setAttribute(
        "src",
        "https://aquazzura.my.salesforce.com/embeddedservice/5.0/esw.min.js"
    );
    s.onload = function() {
        initESW(null);
    };
    document.body.appendChild(s);

} else {
    initESW("https://service.force.com");
}`;
}
